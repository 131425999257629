import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Styles from './ConnectorSelectPicker.module.css'
import InfinityEnums from 'Enums/InfinityEnums'
import { SelectPicker } from 'rsuite'
import { IFsvg, IFText, IFSkeleton } from 'Components'
import { Colors, Icons } from 'Theme'
import styled from 'styled-components'

const StyledSelectPicker = styled(SelectPicker)`
  &.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
    height: 42px;
    display: flex;
  }
  .rs-stack {
    width: 100%;
  }
  .rs-loader-wrapper {
    padding-left: 0.5rem !important;
  }
`

const connectorNames = {
  CHAdeMO: 'Chademo',
  CHADEMO: 'Chademo',
  'Combo ccs': 'Combo CCS',
  COMBO_CCS: 'Combo CCS',
  'GB/T DC': 'GB/T DC',
  'Type 1': 'Type 1',
  'Type 1 ccs': 'Type 1 CCS',
  'Type 2': 'Type 2',
  TYPE_2: 'Type 2',
  Schuko: 'Schuko',
  SCHUKO: 'Schuko',
  'GB/T AC': 'GB/T AC',
}

const handleType = (ACConnectors, DCConnectors) => {
  const connectorMapping = {
    CHAdeMO: { icon: Icons.chademo, style: Styles.IconStyle, value: 'CHADEMO' },
    'Combo ccs': {
      icon: Icons.comboCCS,
      style: Styles.ComboCCSStyle,
      value: 'COMBO_CCS',
    },
    'GB/T DC': {
      icon: Icons.GBT_DC,
      style: Styles.IconStyle,
      value: 'GB/T DC',
    },
    'Type 1': { icon: Icons.type1, style: Styles.IconStyle, value: 'Type 1' },
    'Type 1 ccs': {
      icon: Icons.type1CCS,
      style: Styles.IconStyle,
      value: 'Type 1 ccs',
    },
    'Type 2': { icon: Icons.type2, style: Styles.IconStyle, value: 'TYPE_2' },
    Schuko: { icon: Icons.schuko, style: Styles.IconStyle, value: 'SCHUKO' },
    'GB/T AC': {
      icon: Icons.GBT_AC,
      style: Styles.IconStyle,
      value: 'GB/T AC',
    },
  }

  // Process AC Connectors
  const acConnectorTypes = ACConnectors.map((connector) => ({
    icon: connectorMapping[connector]?.icon,
    name: connectorNames[connector],
    style: connectorMapping[connector]?.style,
    currentType: InfinityEnums.CurrentType.AC,
    value: connectorMapping[connector].value,
  }))

  // Process DC Connectors
  const dcConnectorTypes = DCConnectors.map((connector) => ({
    icon: connectorMapping[connector]?.icon,
    name: connectorNames[connector],
    style: connectorMapping[connector]?.style,
    currentType: InfinityEnums.CurrentType.DC,
    value: connectorMapping[connector].value,
  }))

  return [...acConnectorTypes, ...dcConnectorTypes]
}

const ConnectorSelectPicker = ({
  data,
  onValueChange = () => {},
  initialValue,
  onOpen,
  isLoading,
}) => {
  const [value, setValue] = useState(initialValue)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const types = handleType(data.ACConnectors, data.DCConnectors)
      for (const value of types) {
        setOptions((prev) => {
          return [
            ...prev,
            {
              label: (
                <div className={Styles.Container}>
                  <div className={Styles.LeftContainer}>
                    <div className={Styles.IconContainer}>
                      {value.icon({
                        width: 24,
                        height: 24,
                        color: Colors.black,
                      })}
                    </div>
                    <IFText
                      style={{ color: Colors.text }}
                      className={Styles.Text}
                    >
                      {value.name}
                    </IFText>
                  </div>
                  <div
                    className={Styles.CurrentTypeContainer}
                    style={{ backgroundColor: Colors.primary + '33' }}
                  >
                    <IFText
                      className={Styles.CurrentTypeText}
                      style={{ color: Colors.text }}
                    >
                      {value.currentType}
                    </IFText>
                  </div>
                </div>
              ),
              value: value.value,
              currentType: value.currentType,
            },
          ]
        })
      }
    }
  }, [data])

  return isLoading ? (
    <div style={{ paddingLeft: '1rem' }}>
      <IFSkeleton variant="text" width="13rem" height="3.5rem" />
    </div>
  ) : (
    <StyledSelectPicker
      className={Styles.SelectPicker}
      appearance="subtle"
      value={value}
      onSelect={(value) => {
        setValue(value)
        const connector = options.find((conn) => conn.value === value)
        onValueChange({
          type: value,
          currentType: connector.currentType,
        })
      }}
      data={options}
      cleanable={false}
      size="sm"
      caretAs={() => (
        <div>
          <IFsvg.Down height={20} width={20} fill={Colors.MapSearchBoxIcons} />
        </div>
      )}
      onOpen={onOpen}
    />
  )
}

ConnectorSelectPicker.propTypes = {
  data: PropTypes.array.isRequired,
  onValueChange: PropTypes.func,
  initialValue: PropTypes.string,
}

export default ConnectorSelectPicker
