import styled from 'styled-components'
import Colors from 'Theme/Colors'
import Styles from './ConnectorListItem.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import {
  IFText,
  ConnectorSelectPicker,
  IFTextInput,
  ConnectorOngoingActions,
  IFsvg,
  IFTooltipIconsLoading,
  IFToggle,
} from 'Components'
import { makeStyles } from '@material-ui/core/styles'
import React, {
  useState,
  useImperativeHandle,
  useEffect,
  Fragment,
  useRef,
} from 'react'
import { Accordion, AccordionDetails } from '@material-ui/core'
import PropTypes from 'prop-types'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Icon from '@material-ui/core/Icon'
import InfinityEnums from 'Enums/InfinityEnums'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import icons from 'Theme/Icons'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckIcon from '@mui/icons-material/Check'
import { SelectPicker } from 'rsuite'
import { mapStatus } from 'Utils/ConnectorFunctions'
import { isPropsMatch } from 'Utils/PropsMatch'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

const { ConnectorPowerLevel } = InfinityEnums
const AccordionSummary = withStyles({
  root: {
    '&$expanded': {
      minHeight: 40,
    },
    borderRadius: '5px',
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const useStyles = makeStyles({
  root: {
    boxShadow: '0px 0px 0px 0px',
    borderRadius: '5px !important',
  },
  AccordionSummary: {
    padding: '0px',
    boxShadow: `0px 0px 2px ${Colors.BoxShadowColor}`,
  },
  content: {
    margin: 0,
    height: '3rem',
  },
})
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.white,
    boxShadow: `0px 0px 4px 0px ${Colors.tooltipBoxShadow}`,
    maxWidth: 'none',
  },
}))

const handleMaxPowerLogo = (maxPower) => {
  if (maxPower <= ConnectorPowerLevel.LEVEL1)
    return <div className={Styles.FlashContainer}>{icons.flash}</div>
  else if (maxPower <= ConnectorPowerLevel.LEVEL2)
    return [...Array(2)].map((e, index) => (
      <div key={index} className={Styles.FlashContainer}>
        {icons.flash}
      </div>
    ))
  else
    return [...Array(3)].map((e, index) => (
      <div key={index} className={Styles.FlashContainer}>
        {icons.flash}
      </div>
    ))
}

const handleIcon = (type) => {
  switch (type) {
    case InfinityEnums.ConnectorType.TYPE_2:
      return icons.type2({
        width: 24,
        height: 24,
        color: Colors.black,
      })
    case InfinityEnums.ConnectorType.CHADEMO:
      return icons.chademo({
        width: 24,
        height: 24,
        color: Colors.black,
      })
    case InfinityEnums.ConnectorType.COMBO_CCS:
      return icons.comboCCS({
        width: 24,
        height: 24,
        color: Colors.black,
      })
    case InfinityEnums.ConnectorType.SCHUKO:
      return icons.schuko({
        width: 24,
        height: 24,
        color: Colors.black,
      })
    case 'GB/T DC':
      return icons.GBT_DC({
        width: 24,
        height: 24,
        color: Colors.black,
      })
    case 'Type 1':
      return icons.type1({
        width: 24,
        height: 24,
        color: Colors.black,
      })
    case 'Type 1 ccs':
      return icons.type1CCS({
        width: 24,
        height: 24,
        color: Colors.black,
      })
    case 'GB/T AC':
      return icons.GBT_AC({
        width: 24,
        height: 24,
        color: Colors.black,
      })
    default:
      return null
  }
}

const MaxPowerDiv = styled.div`
  background-color: ${Colors.MaxPowerBackground};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`
const StatusContainer = styled.div`
  background-color: ${Colors.GreyBackground};
  color: ${(props) => props.status};
`

const LastUpdatedContainer = styled.div`
  border: 2px solid ${Colors.GreyBackground};
  background-color: ${Colors.white};
`

const ConnectorListItem = React.forwardRef(
  (
    {
      maxPower,
      status,
      type,
      uid,
      lastUpdateTime,
      onUnlockClick,
      onStartClick,
      onStopClick,
      onSyncClick,
      isEdit,
      onMaxPowerChange,
      onTypeChange,
      isStopTxLoading,
      isUnlockLoading,
      isSyncLoading,
      isStartTxDead,
      isStopTxDead,
      isChargePointOffline,
      editPermission,
      session,
      onStatusChange = () => {},
      onReserveClick,
      onCancelReserveClick,
      reservation,
      tenantUser,
      meterValue,
      errorCode,
      vendorErrorCode,
      vendorId,
      fetchConnectorTypesRequestState,
      connectorTypes,
    },
    ref,
  ) => {
    const onClickAccordionHandler = () => {
      if (!isEdit)
        setAccordExpanded((expanded) => {
          return !expanded
        })
    }

    const handleStatus = (status) => {
      if (isChargePointOffline) return Colors.OfflineConnector

      switch (status) {
        case InfinityEnums.ConnectorStatus.AVAILABLE:
          return Colors.AvailableConnector
        case InfinityEnums.ConnectorStatus.PREPARING:
          return Colors.PreparingConnector
        case InfinityEnums.ConnectorStatus.CHARGING:
          return Colors.ChargingConnector
        case InfinityEnums.ConnectorStatus.SUSPENDEDEV:
          return Colors.SuspendedConnector
        case InfinityEnums.ConnectorStatus.SUSPENDEDEVSE:
          return Colors.SuspendedConnector
        case InfinityEnums.ConnectorStatus.FINISHING:
          return Colors.FinishingConnector
        case InfinityEnums.ConnectorStatus.RESERVED:
          return Colors.ReservedConnector
        case InfinityEnums.ConnectorStatus.UNAVAILABLE:
          return Colors.UnAvailableConnector
        case InfinityEnums.ConnectorStatus.OFFLINE:
          return Colors.OfflineConnector
        case InfinityEnums.ConnectorStatus.FAULTED:
          return Colors.FaultedConnector
        default:
          return Colors.black
      }
    }
    const updateInputValue = (event) => {
      setMaxPowerValue(event.target.value)
      onMaxPowerChange(event.target.value)
    }

    const resetMaxPowerValue = () => {
      setMaxPowerValue(maxPower)
    }

    const { t } = useTranslation()
    const classes = useStyles()

    const [accordExpanded, setAccordExpanded] = React.useState(false)
    const [uidHover, setUidHover] = useState(false)
    const [isUidCopied, setIsUidCopied] = useState(false)
    const [maxPowerValue, setMaxPowerValue] = useState(maxPower)
    const statusToggleRef = useRef()
    const toolTipTitles = [
      {
        title: t('ChargePointDetails.MeterValue'),
        detail: meterValue ? meterValue : '—',
      },
      {
        title: t('ChargePointDetails.ErrorCode'),
        detail: errorCode ? errorCode : '—',
      },
      {
        title: t('ChargePointDetails.VendorErrorCode'),
        detail: vendorErrorCode ? vendorErrorCode : '—',
      },
      {
        title: t('ChargePointDetails.VendorId'),
        detail: vendorId ? vendorId : '—',
      },
    ]
    const toolTipTitle = (title, details, index) => {
      return (
        <div
          key={`toolTipTitle ${index}`}
          className={
            index === 0 ? Styles.ToolTipContainerFirst : Styles.ToolTipContainer
          }
        >
          <div className={Styles.LeftToolTipDetails}>
            <IFText
              style={{ color: Colors.text }}
              className={Styles.ToolTipTitle}
            >
              {title}
            </IFText>
          </div>
          <div className={Styles.RightToolTipDetails}>
            <IFText
              style={{ color: Colors.tooltipText }}
              className={Styles.ToolTipDetail}
            >
              {details}
            </IFText>
          </div>
        </div>
      )
    }

    const resetStatusValue = () => {
      statusToggleRef.current?.setValue(
        mapStatus(status).text === InfinityEnums.ConnectorStatus.AVAILABLE,
      )
    }

    const closeAccordion = () => {
      setAccordExpanded(false)
    }

    useImperativeHandle(ref, () => ({
      resetMaxPowerValue,
      closeAccordion,
      resetStatusValue,
    }))

    useEffect(() => {
      setMaxPowerValue(maxPower)
    }, [maxPower])

    useEffect(() => {
      statusToggleRef.current?.setValue(
        mapStatus(status).text === InfinityEnums.ConnectorStatus.AVAILABLE,
      )
    }, [status])

    const animateUid = () => {
      setIsUidCopied(!isUidCopied)
      setTimeout(() => setIsUidCopied(false), 2000)
    }

    const permissionArray = [
      InfinityEnums.TenantUserPermission.CAN_START_TX,
      InfinityEnums.TenantUserPermission.CAN_STOP_TX,
      InfinityEnums.TenantUserPermission.CAN_RESERVE_CONNECTOR,
      InfinityEnums.TenantUserPermission.CAN_CANCEL_RESERVATION,
      InfinityEnums.TenantUserPermission.CAN_UNLOCK_CONNECTOR,
      InfinityEnums.TenantUserPermission.CAN_TRIGGER_MESSAGE,
    ]

    return (
      <div>
        <div>
          <Accordion
            onClick={onClickAccordionHandler}
            expanded={
              isEdit
                ? false
                : accordExpanded &&
                  editPermission &&
                  permissionArray.some((permission) =>
                    tenantUser.permissions.includes(permission),
                  )
            }
            className={classes.root}
          >
            <AccordionSummary
              classes={{ content: classes.content }}
              className={classes.AccordionSummary}
            >
              <MaxPowerDiv className={Styles.MaxPowerContainer}>
                <div className={Styles.ChargingLogoContainer}>
                  {handleMaxPowerLogo(maxPowerValue)}
                </div>
                <div className={Styles.TextContainer}>
                  {isEdit ? (
                    <IFTextInput
                      className={Styles.TextInputStyle}
                      isFixed={true}
                      fontSize={12}
                      value={maxPowerValue}
                      onChange={updateInputValue}
                    />
                  ) : (
                    <IFText className={Styles.MaxPowerNumber}>
                      {maxPower}
                    </IFText>
                  )}
                  <IFText className={Styles.MaxPowerText}>{`${t(
                    'ConnectorListItem.kW',
                  )}`}</IFText>
                </div>
              </MaxPowerDiv>
              <div className={Styles.ContentContainer}>
                {isEdit ? (
                  <div className={Styles.SelectPickerContainer}>
                    <ConnectorSelectPicker
                      data={connectorTypes}
                      initialValue={type}
                      onValueChange={(value) => onTypeChange(value)}
                      isLoading={fetchConnectorTypesRequestState}
                    />
                  </div>
                ) : (
                  <div className={Styles.LeftDetailsContainer}>
                    <div className={Styles.IconContainer}>
                      {handleIcon(type)}
                    </div>
                    <div
                      className={
                        isEdit ? Styles.UIDContainerEdit : Styles.UIDContainer
                      }
                    >
                      <div>
                        <IFText>{`#${uid}`}</IFText>
                      </div>
                      <div
                        className={Styles.UidCopyContainer}
                        onMouseEnter={() => setUidHover(true)}
                        onMouseLeave={() => setUidHover(false)}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {uidHover ? (
                          <div
                            className={Styles.iconContainer}
                            onClick={() => {
                              navigator.clipboard.writeText(uid)
                            }}
                          >
                            <CheckIcon
                              className={
                                isUidCopied ? Styles.visible : Styles.hide
                              }
                              style={{
                                color: Colors.primary,
                              }}
                            />
                            <ContentCopyIcon
                              onClick={animateUid}
                              style={{ color: Colors.ClipboardCopy }}
                              className={
                                !isUidCopied ? Styles.visible : Styles.hide
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}

                {isEdit ? (
                  <div
                    className={
                      isEdit ? Styles.UIDContainerEdit : Styles.UIDContainer
                    }
                  >
                    <div>
                      <IFText>{`#${uid}`}</IFText>
                    </div>
                    <div
                      className={Styles.UidCopyContainer}
                      onMouseEnter={() => setUidHover(true)}
                      onMouseLeave={() => setUidHover(false)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {uidHover ? (
                        <div
                          className={Styles.iconContainer}
                          onClick={() => {
                            navigator.clipboard.writeText(uid)
                          }}
                        >
                          <CheckIcon
                            className={
                              isUidCopied ? Styles.visible : Styles.hide
                            }
                            style={{
                              color: Colors.primary,
                            }}
                          />
                          <ContentCopyIcon
                            onClick={animateUid}
                            style={{ color: Colors.ClipboardCopy }}
                            className={
                              !isUidCopied ? Styles.visible : Styles.hide
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <div
                  className={
                    isEdit
                      ? Styles.MiddleContentContainerEdit
                      : editPermission
                      ? Styles.MiddleContentContainer
                      : Styles.MiddleContentContainerNoPermissions
                  }
                >
                  {isEdit ? (
                    isChargePointOffline ? (
                      <StatusContainer
                        status={handleStatus(status)}
                        className={Styles.StatusContainer}
                      >
                        <IFText className={Styles.StatusText}>
                          {InfinityEnums.ConnectorStatus.OFFLINE}
                        </IFText>
                      </StatusContainer>
                    ) : (
                      <div className={Styles.StatusContainerEdit}>
                        <IFToggle
                          ref={statusToggleRef}
                          height={26}
                          fontSize={14}
                          fontWeight={500}
                          marginLeftRight={8}
                          checkedText={InfinityEnums.ConnectorStatus.AVAILABLE}
                          uncheckedText={
                            InfinityEnums.ConnectorStatus.UNAVAILABLE
                          }
                          defaultChecked={
                            mapStatus(status).text ===
                            InfinityEnums.ConnectorStatus.AVAILABLE
                          }
                          onChange={(isChecked) => {
                            onStatusChange(
                              isChecked
                                ? InfinityEnums.ConnectorStatus.AVAILABLE
                                : InfinityEnums.ConnectorStatus.UNAVAILABLE,
                            )
                          }}
                          checkedColor={Colors.AvailableConnector}
                          uncheckedColor={Colors.UnAvailableConnector}
                        />
                      </div>
                    )
                  ) : (
                    <div className={Styles.StatusContainerWrapper}>
                      <StatusContainer
                        status={handleStatus(status)}
                        className={Styles.StatusContainer}
                      >
                        <IFText className={Styles.StatusText}>
                          {isChargePointOffline
                            ? InfinityEnums.ConnectorStatus.OFFLINE
                            : status}
                        </IFText>
                      </StatusContainer>
                      <LastUpdatedContainer
                        className={Styles.LastUpdatedContainer}
                      >
                        <IFsvg.LastUpdated
                          height={18}
                          width={18}
                          fill={Colors.LastUpdatedColor}
                          className={Styles.ClockIcon}
                        />
                        <IFText style={{ color: Colors.LastUpdatedColor }}>
                          {lastUpdateTime}
                        </IFText>
                      </LastUpdatedContainer>
                      <div className={Styles.InfoIconConatiner}>
                        <HtmlTooltip
                          title={
                            <div className={Styles.ToolTipWrapper}>
                              {toolTipTitles.map((item, index) =>
                                toolTipTitle(item.title, item.detail, index),
                              )}
                            </div>
                          }
                          placement="right-start"
                        >
                          <InfoIcon
                            style={{ color: Colors.tooltipGreyBackground }}
                            className={Styles.InfoIcon}
                          />
                        </HtmlTooltip>
                      </div>
                    </div>
                  )}
                </div>
                {isEdit ? null : editPermission ? (
                  <div className={Styles.RightDetailsWrapper}>
                    <Fragment>
                      <div className={Styles.ActionsTextContainer}>
                        <IFText>{t('ConnectorListItem.Actions')}</IFText>
                      </div>
                      <div className={Styles.ArrowContainer}>
                        {accordExpanded ? (
                          <Icon component={KeyboardArrowUpIcon} />
                        ) : (
                          <Icon component={KeyboardArrowDownIcon} />
                        )}
                      </div>
                    </Fragment>
                  </div>
                ) : null}
              </div>
            </AccordionSummary>
            <AccordionDetails className={Styles.ButtonContainer}>
              {(session ||
                (reservation &&
                  status === InfinityEnums.ConnectorStatus.RESERVED)) && (
                <div
                  className={Styles.OngoingSessionContainer}
                  onClick={(e) => e.stopPropagation()}
                >
                  <ConnectorOngoingActions
                    chargingTokenUid={
                      session
                        ? session.chargingToken.uid
                        : reservation.chargingToken.uid
                    }
                    user={
                      session
                        ? session.chargingToken.user
                        : reservation.chargingToken.user
                    }
                    status={status}
                    powerConsumed={
                      session?.transaction?.meterEnd -
                      session?.transaction?.meterStart
                    }
                    startedAt={session?.startedAt}
                    reservation={reservation}
                    tokenType={
                      session
                        ? session?.chargingToken?.type
                        : reservation?.chargingToken?.type
                    }
                  />
                </div>
              )}
              {!(isChargePointOffline || isStartTxDead) &&
                tenantUser.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_START_TX,
                ) && (
                  <IFTooltipIconsLoading
                    onClick={onStartClick}
                    title={t('ConnectorListItem.StartTx')}
                    Icon={IFsvg.Start}
                    FilledIcon={IFsvg.StartFilled}
                    iconClassname={Styles.Icon}
                  />
                )}
              {!(isChargePointOffline || isStopTxDead) &&
                tenantUser.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_STOP_TX,
                ) && (
                  <IFTooltipIconsLoading
                    onClick={onStopClick}
                    title={t('ConnectorListItem.StopTx')}
                    iconColor={Colors.red}
                    iconBackgroundColor={Colors.red}
                    Icon={IFsvg.Stop}
                    FilledIcon={IFsvg.StopFilled}
                    iconClassname={Styles.Icon}
                    isLoading={isStopTxLoading}
                    animationDisabled={false}
                  />
                )}
              {!isChargePointOffline &&
                status === InfinityEnums.ConnectorStatus.AVAILABLE &&
                tenantUser.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_RESERVE_CONNECTOR,
                ) && (
                  <IFTooltipIconsLoading
                    onClick={onReserveClick}
                    title={t('ConnectorListItem.ReserveConnector')}
                    Icon={IFsvg.ScheduleSend}
                    FilledIcon={IFsvg.ScheduleSendFill}
                    iconClassname={Styles.Icon}
                  />
                )}

              {!isChargePointOffline &&
                status === InfinityEnums.ConnectorStatus.RESERVED &&
                tenantUser.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_CANCEL_RESERVATION,
                ) && (
                  <IFTooltipIconsLoading
                    onClick={onCancelReserveClick}
                    title={t('ConnectorListItem.CancelConnectorReservation')}
                    Icon={IFsvg.CancelScheduleSend}
                    FilledIcon={IFsvg.CancelScheduleSendFill}
                    iconClassname={Styles.Icon}
                    animationDisabled={true}
                  />
                )}
              {!isChargePointOffline &&
                tenantUser.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_UNLOCK_CONNECTOR,
                ) && (
                  <IFTooltipIconsLoading
                    onClick={onUnlockClick}
                    title={t('ConnectorListItem.Unlock')}
                    Icon={IFsvg.Unlock}
                    FilledIcon={IFsvg.UnlockFilled}
                    isLoading={isUnlockLoading}
                    iconClassname={Styles.Icon}
                    animationDisabled={false}
                  />
                )}
              {!isChargePointOffline &&
                tenantUser.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_TRIGGER_MESSAGE,
                ) && (
                  <IFTooltipIconsLoading
                    onClick={onSyncClick}
                    title={t('ConnectorListItem.RefreshStatus')}
                    Icon={IFsvg.Sync}
                    FilledIcon={IFsvg.Sync}
                    isLoading={isSyncLoading}
                    iconClassname={Styles.Icon}
                    animationDisabled={false}
                  />
                )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    )
  },
)
ConnectorListItem.propTypes = {
  maxPower: PropTypes.number,
  status: PropTypes.string,
  type: PropTypes.string,
  uid: PropTypes.number,
  lastUpdateTime: PropTypes.object,
  onUnlockClick: PropTypes.func,
  onStartClick: PropTypes.func,
  onStopClick: PropTypes.func,
  isEdit: PropTypes.bool,
  onMaxPowerChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  isStartTxLoading: PropTypes.bool,
  isStopTxLoading: PropTypes.bool,
  isUnlockLoading: PropTypes.bool,
  editPermission: PropTypes.bool,
  session: PropTypes.object,
  onSyncClick: PropTypes.func,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'maxPower',
    'status',
    'type',
    'uid',
    'lastUpdateTime',
    'isEdit',
    'isStopTxLoading',
    'isUnlockLoading',
    'isSyncLoading',
    'isStartTxDead',
    'isStopTxDead',
    'isChargePointOffline',
    'editPermission',
    'session',
    'fetchConnectorTypesRequestState',
    'connectorTypes',
    'reservation',
  ])
}

export default React.memo(ConnectorListItem, shouldSkipRender)
