import InfinityEnums from 'Enums/InfinityEnums'
var stringSimilarity = require('string-similarity')

const getFormattedStationsList = (state) => {
  const stationList = getStationsList(state)
  if (stationList.length) {
    let connectors = []
    let chargePoints = []
    let maxPower = 0
    let response = []
    let status = ''

    connectors = [...Array(stationList.length)].map((e) => Array())
    chargePoints = [...Array(stationList.length)].map((e) => Array())
    stationList.map((station, index) => {
      if (station.chargePoints) {
        station.chargePoints.map((chargePoint) => {
          maxPower = 0
          chargePoint.connectors.map((connector) => {
            if (!chargePoint.isOnline)
              status = InfinityEnums.ConnectorStatus.OFFLINE
            else status = connector.status
            if (chargePoint.isActivated && connector.uid !== 0) {
              fillConnectors(status, connectors, index)
            }
            if (connector.maxPower > maxPower && connector.uid !== 0)
              maxPower = connector.maxPower
          })
          fillChargePoints(maxPower, chargePoints, index)
        })
        connectors[index].sort((a, b) => (a.status > b.status ? 1 : -1))
        chargePoints[index].sort((a, b) => (a.level > b.level ? -1 : 1))
        response.push({
          name: station.name,
          lastUpdated: station.updatedAt,
          connectors: connectors[index],
          chargePoints: chargePoints[index],
          location: station.location,
          isNew: station.isNew,
          isPrivateAccess: station.isPrivateAccess,
        })
      } else
        response.push({
          name: station.name,
          lastUpdated: station.updatedAt,
          location: station.location,
          isNew: station.isNew,
          isPrivateAccess: station.isPrivateAccess,
        })
    })
    return response
  } else return stationList
}

const getFormattedMoveStationsList = (state) => {
  const stationList = getMoveStationsList(state)
  if (stationList.length) {
    let connectors = []
    let chargePoints = []
    let maxPower = 0
    let response = []
    let status = ''

    connectors = [...Array(stationList.length)].map((e) => Array())
    chargePoints = [...Array(stationList.length)].map((e) => Array())
    stationList.map((station, index) => {
      station.chargePoints.map((chargePoint) => {
        maxPower = 0
        chargePoint.connectors.map((connector) => {
          if (!chargePoint.isOnline)
            status = InfinityEnums.ConnectorStatus.OFFLINE
          else status = connector.status
          if (chargePoint.isActivated && connector.uid !== 0)
            fillConnectors(status, connectors, index)
          if (connector.maxPower > maxPower && connector.uid !== 0)
            maxPower = connector.maxPower
        })
        fillChargePoints(maxPower, chargePoints, index)
      })
      connectors[index].sort((a, b) => (a.status > b.status ? 1 : -1))
      chargePoints[index].sort((a, b) => (a.level > b.level ? -1 : 1))
      response.push({
        name: station.name,
        lastUpdated: station.updatedAt,
        connectors: connectors[index],
        chargePoints: chargePoints[index],
      })
    })
    return response
  } else return stationList
}

const fillConnectors = (status, connectors, index) => {
  let newStatus = status
  if (
    status === InfinityEnums.ConnectorStatus.PREPARING ||
    status === InfinityEnums.ConnectorStatus.SUSPENDEDEV ||
    status === InfinityEnums.ConnectorStatus.SUSPENDEDEVSE ||
    status === InfinityEnums.ConnectorStatus.RESERVED
  )
    newStatus = InfinityEnums.ConnectorStatus.PREPARING
  else if (
    status === InfinityEnums.ConnectorStatus.CHARGING ||
    status === InfinityEnums.ConnectorStatus.FINISHING
  )
    newStatus = InfinityEnums.ConnectorStatus.CHARGING
  else if (
    status === InfinityEnums.ConnectorStatus.UNAVAILABLE ||
    status === InfinityEnums.ConnectorStatus.FAULTED
  )
    newStatus = InfinityEnums.ConnectorStatus.UNAVAILABLE
  const value = connectors[index].findIndex(
    (connector) => connector.status === newStatus,
  )
  if (value !== -1) connectors[index][value].number += 1
  else connectors[index].push({ number: 1, status: newStatus })
}

const fillChargePoints = (maxPower, chargePoints, index) => {
  let level =
    maxPower <= InfinityEnums.ConnectorPowerLevel.LEVEL1
      ? 1
      : maxPower <= InfinityEnums.ConnectorPowerLevel.LEVEL2
      ? 2
      : 3
  const value = chargePoints[index].findIndex(
    (chargePoint) => chargePoint.level === level,
  )
  if (value !== -1) chargePoints[index][value].number += 1
  else chargePoints[index].push({ number: 1, level: level })
}

const getStationsList = (state) => {
  return state.main.stationState.stations
}

const isSelectedStationChargePointsEmpty = (state) => {
  const selectedIndex = getSelectedIndex(state)
  return state.main.stationState.stations[selectedIndex] &&
    state.main.stationState.stations[selectedIndex].chargePoints
    ? state.main.stationState.stations[selectedIndex].chargePoints.length === 0
    : true
}

const getMoveStationsList = (state) => {
  return state.main.stationState.moveStations
}

const getFetchStationListRequestState = (state) => {
  return state.main.stationState.fetchStationListRequestState
}

const getFetchMoveStationListRequestState = (state) => {
  return state.main.stationState.fetchMoveStationListRequestState
}

const getSelectedIndex = (state) => {
  return state.main.stationState.selectedIndex
}

const getPaginationOffset = (state) => {
  return state.main.stationState.paginationOffset
}

const getStationsFilter = (state) => {
  return state.main.stationState.stationsFilter
}

const getSelectedMoveIndex = (state) => {
  return state.main.stationState.selectedMoveIndex
}

const getPaginationOffsetMove = (state) => {
  return state.main.stationState.paginationOffsetMove
}

const getMoveStationsFilter = (state) => {
  return state.main.stationState.moveStationsFilter
}

const getUpdateStationRequestState = (state) => {
  return state.main.stationState.updateStationRequestState
}

const getAddStationRequestState = (state) => {
  return state.main.stationState.addStationRequestState
}

const getSelectedStationDetails = (state) => {
  const stations = getStationsList(state)
  const selectedIndex = getStationDetailsSelectedIndex(state)
  return stations[selectedIndex]
}

const getStationDetailsSelectedIndex = (state) => {
  return state.main.stationState.stationDetailsSelectedIndex
}

const getSelectedStation = (state) => {
  const stations = getStationsList(state)
  const selectedIndex = getSelectedIndex(state)
  return stations[selectedIndex]
}

const getSelectedMoveStation = (state) => {
  const stations = getMoveStationsList(state)
  const selectedIndex = getSelectedMoveIndex(state)
  return stations[selectedIndex]
}

const getDeleteStationRequestState = (state) => {
  return state.main.stationState.deleteStationRequestState
}

const getDeleteStationEmitterState = (state) => {
  return state.main.stationState.deleteStationEmitterState
}

const getAddCityRequestState = (state) => {
  return state.main.stationState.addStationCityRequestState
}

const getCities = (state) => {
  const filters = state.main.stationState.stationsFilter
  for (let filter of filters) {
    if (filter.title === 'City') return filter.data
  }
  return []
}

const getStationMapClusters = (state) => {
  return state.main.stationState.stationMapClusters
}

const getFetchStationMapClustersRequestState = (state) => {
  return state.main.stationState.fetchStationMapClustersRequestState
}

const getStationMapData = (state) => {
  return state.main.stationState.stationMapData
}

const getStationMapFilters = (state) => {
  return state.main.stationState.stationsMapFilter
}
const getfetchStationHeatMapRequestState = (state) => {
  return state.main.stationState.fetchStationHeatMapRequestState
}

const getStationsCount = (state) => {
  return state.main.stationState.stationsCount
}
const getFetchStationListCountRequestState = (state) => {
  return state.main.stationState.fetchStationListCountRequestState
}

const getFetchStationMapClustersSearchRequestState = (state) => {
  return state.main.stationState.fetchStationMapClustersSearchRequestState
}

const getStationMapClustersSearchWord = (state) => {
  return state.main.stationState.stationMapClustersSearchWord
}

const getStationMapClustersSearch = (state) => {
  let clusters = state.main.stationState.stationMapClustersSearch
  let clustersFormatted = []
  for (const cluster of clusters) {
    if (cluster.id) {
      for (const childCluster of cluster.properties.clusterChildrenLocations) {
        clustersFormatted.push(childCluster)
      }
    } else {
      clustersFormatted.push({
        coordinates: [
          cluster.properties.location.longitude,
          cluster.properties.location.latitude,
        ],
        searchFilters: [
          cluster.properties.name,
          ...cluster.properties.chargePoints.flatMap((cp) => [cp.name, cp.uid]),
        ],
        zoomLevel: cluster.properties.zoomLevel,
      })
    }
  }
  let searchWord = getStationMapClustersSearchWord(state)
  let filteredClusters = []
  for (const cluster of clustersFormatted) {
    let matchSimilarity = stringSimilarity.findBestMatch(
      searchWord,
      cluster.searchFilters,
    )
    filteredClusters.push({
      rating: matchSimilarity.bestMatch.rating,
      coordinates: cluster.coordinates,
      searchFilter: cluster.searchFilters,
      zoomLevel: cluster.zoomLevel,
    })
  }

  return filteredClusters.sort((a, b) => b.rating - a.rating)
}

const StationSelectors = {
  getFormattedStationsList,
  getStationsList,
  getFetchStationListRequestState,
  getSelectedIndex,
  getPaginationOffset,
  getStationsFilter,
  getUpdateStationRequestState,
  getAddStationRequestState,
  getMoveStationsList,
  getFormattedMoveStationsList,
  getFetchMoveStationListRequestState,
  getSelectedMoveIndex,
  getPaginationOffsetMove,
  getMoveStationsFilter,
  getSelectedStationDetails,
  getStationDetailsSelectedIndex,
  getSelectedStation,
  getSelectedMoveStation,
  getDeleteStationRequestState,
  getDeleteStationEmitterState,
  getAddCityRequestState,
  getCities,
  getStationMapClusters,
  getFetchStationMapClustersRequestState,
  getStationMapData,
  getStationMapFilters,
  getfetchStationHeatMapRequestState,
  getStationsCount,
  isSelectedStationChargePointsEmpty,
  getFetchStationListCountRequestState,
  getFetchStationMapClustersSearchRequestState,
  getStationMapClustersSearch,
  getStationMapClustersSearchWord,
}

export default StationSelectors
