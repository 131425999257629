import React, { useState, useEffect, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import Styles from './StationDetailsModal.module.css'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import StationActions from 'Stores/Station/Actions'
import StationSelectors from 'Stores/Station/Selectors'
import RequestState from 'Enums/RequestState'
import {
  IFMap,
  IFModal,
  IFText,
  IFTextInput,
  IFDialog,
  IFTooltipIconsLoading,
  IFsvg,
  IFToggle,
} from 'Components'
import InfinityEnums from 'Enums/InfinityEnums'
import icons from 'Theme/Icons'
import PropTypes from 'prop-types'
import validator from 'validator'
import AuthSelectors from 'Stores/Auth/Selectors'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Icon from '@material-ui/core/Icon'
import AddIcon from '@mui/icons-material/Add'
import { makeStyles } from '@material-ui/core/styles'
import AddCityModal from 'Containers/AddCityModal/AddCityModal'
import FormControl from '@material-ui/core/FormControl'
import { SelectPicker } from 'rsuite'

const useStyles = makeStyles({
  root: {
    boxShadow: `0px 0px 4px ${Colors.black} `,
    borderRadius: '6px',
    marginTop: '1px',
    marginBottom: '-4px',
  },
})

const StationDetailsModal = React.forwardRef(
  (
    {
      index,
      name,
      connectors,
      chargePoints,
      location,
      isPrivateAccess,
      updateStationRequestState,
      updateStation,
      tenantUser,
      deleteStationRequestState,
      deleteStation,
      cities,
      stationsFilter,
      addCityRequestState,
      deleteStationEmitterState,
    },
    ref,
  ) => {
    const Classes = useStyles()
    const addCityModalRef = useRef()
    const [citiesOptions, setCitiesOptions] = useState([])
    useEffect(() => {
      let citiesOptionsNew = []
      for (const index in cities) {
        citiesOptionsNew.push({
          label: (
            <IFText
              style={{
                color: Colors.text,
                fontSize: '16px',
              }}
            >
              {cities[index]}
            </IFText>
          ),
          value: cities[index],
        })
      }
      setCitiesOptions(citiesOptionsNew)
    }, [stationsFilter])

    useEffect(() => {
      if (addCityRequestState === RequestState.SUCCEEDED) {
        if (addCityModalRef.current)
          setCity(addCityModalRef.current.returnLastAddedCity())
      }
    }, [addCityRequestState])

    useEffect(() => {
      setStationName(name)
    }, [name])

    useEffect(() => {
      if (updateStationRequestState === RequestState.SUCCEEDED) {
        setIsEditing(false)
      }
    }, [updateStationRequestState])

    useEffect(() => {
      setStreetName(location.streetName)
      setCity(location.city)
      setCoordinates(`${location.latitude} , ${location.longitude}`)
    }, [location])

    useEffect(() => {
      if (deleteStationRequestState === RequestState.SUCCEEDED) {
        if (confirmDeleteDialog.current) confirmDeleteDialog.current.dismiss()
        ref.current.handleClose()
      }
    }, [deleteStationRequestState])
    useEffect(() => {
      if (deleteStationEmitterState === RequestState.SUCCEEDED) {
        if (confirmDeleteDialog.current) confirmDeleteDialog.current.dismiss()
        ref.current.handleClose()
      }
    }, [deleteStationEmitterState])

    const initialStationName = name
    const initialStreetName = location.streetName
    const initialCity = location.city
    const initialIsPrivateAccess = isPrivateAccess
    const initialCoordinates = `${location.latitude} , ${location.longitude}`
    const [isEditing, setIsEditing] = useState(false)
    const [stationName, setStationName] = useState(name)
    const [streetName, setStreetName] = useState(location.streetName)
    const [city, setCity] = useState(location.city)
    const [coordinates, setCoordinates] = useState(
      `${location.latitude} , ${location.longitude}`,
    )
    const [isPrivateAccessValue, setIsPrivateAccessValue] =
      useState(isPrivateAccess)
    const { t } = useTranslation()
    const confirmDeleteDialog = useRef()
    const isPrivateAccessRef = useRef()

    const resetValues = () => {
      setStationName(name)
      setStreetName(location.streetName)
      setCity(location.city)
      setCoordinates(`${location.latitude} , ${location.longitude}`)
      setIsPrivateAccessValue(isPrivateAccess)
      if (isPrivateAccessRef.current)
        isPrivateAccessRef.current.setValue(!isPrivateAccess)
    }
    const handleBoxColor = (status) => {
      switch (status) {
        case InfinityEnums.ConnectorStatus.AVAILABLE:
          return { backgroundColor: Colors.AvailableConnector }
        case InfinityEnums.ConnectorStatus.PREPARING:
          return { backgroundColor: Colors.PreparingConnector }
        case InfinityEnums.ConnectorStatus.CHARGING:
          return { backgroundColor: Colors.ChargingConnector }
        case InfinityEnums.ConnectorStatus.SUSPENDEDEV:
          return { backgroundColor: Colors.SuspendedConnector }
        case InfinityEnums.ConnectorStatus.SUSPENDEDEVSE:
          return { backgroundColor: Colors.SuspendedConnector }
        case InfinityEnums.ConnectorStatus.FINISHING:
          return { backgroundColor: Colors.FinishingConnector }
        case InfinityEnums.ConnectorStatus.RESERVED:
          return { backgroundColor: Colors.ReservedConnector }
        case InfinityEnums.ConnectorStatus.OFFLINE:
          return { backgroundColor: Colors.OfflineConnector }
        case InfinityEnums.ConnectorStatus.UNAVAILABLE:
          return { backgroundColor: Colors.UnAvailableConnector }
        default:
          return { backgroundColor: Colors.black }
      }
    }
    const handleChargingLogo = (type) => {
      switch (type) {
        case 1:
          return icons.normalCharging
        case 2:
          return icons.fastCharging
        case 3:
          return icons.superCharging
        default:
          return icons.normalCharging
      }
    }

    const handleDone = () => {
      let newStation = {}
      if (name !== stationName) newStation.name = stationName
      if (isPrivateAccess !== isPrivateAccessValue)
        newStation.isPrivateAccess = isPrivateAccessValue
      if (
        location.streetName !== streetName ||
        location.city !== city ||
        `${location.latitude} , ${location.longitude}` !== coordinates
      )
        newStation.location = {
          streetName: streetName,
          city: city,
          latitude: coordinates.replaceAll(' ', '').split(',')[0],
          longitude: coordinates.replaceAll(' ', '').split(',')[1],
        }

      if (
        newStation.name ||
        newStation.location ||
        newStation.hasOwnProperty('isPrivateAccess')
      ) {
        updateStation(index, newStation)
      } else setIsEditing(false)
    }
    const handleCloseIcon = () => {
      setIsEditing(false)
      resetValues()
    }

    const handleDoneRef = useRef(handleDone)
    useEffect(() => {
      handleDoneRef.current = handleDone
    })

    return (
      <Fragment>
        <IFModal
          width={'65rem'}
          onClose={handleCloseIcon}
          title={
            isEditing ? (
              <IFTextInput
                fontSize={20}
                style={{ color: Colors.text }}
                value={stationName}
                className={Styles.title}
                onChange={(e) => {
                  setStationName(e.target.value)
                }}
                maxWidth={'50rem'}
              />
            ) : (
              stationName
            )
          }
          ref={ref}
        >
          <Fragment>
            <div className={Styles.topContainer}>
              <div className={Styles.topItem}>
                {connectors
                  ? connectors.map((connector, index) => {
                      return (
                        <div
                          key={`connector ${index}`}
                          className={Styles.topLeftContainer}
                        >
                          <IFText
                            className={Styles.number}
                            style={{
                              color: Colors.text,
                            }}
                          >
                            {connector.number}
                          </IFText>
                          <div
                            className={Styles.box}
                            style={handleBoxColor(connector.status)}
                          />
                        </div>
                      )
                    })
                  : null}
              </div>
              <div className={Styles.topItem}>
                {chargePoints
                  ? chargePoints.map((chargePoint, index) => {
                      return (
                        <div
                          key={`ChargePoint ${index}`}
                          className={Styles.topLeftContainer}
                        >
                          <IFText
                            className={Styles.number}
                            style={{ color: Colors.text }}
                          >
                            {chargePoint.number}
                          </IFText>
                          <img
                            alt={`level ${chargePoint.level}`}
                            className={Styles.icon}
                            src={handleChargingLogo(chargePoint.level)}
                          />
                        </div>
                      )
                    })
                  : null}
              </div>
            </div>
            <div className={Styles.bottomWrapper}>
              <div className={Styles.bottomContainer}>
                <div className={Styles.bottomItem}>
                  <IFText
                    className={Styles.textTitle}
                    style={{ color: Colors.text, marginTop: 0 }}
                  >
                    {t('StationDetailsModal.StreetName')}
                  </IFText>
                  {isEditing ? (
                    <IFTextInput
                      style={{ color: Colors.text }}
                      className={Styles.textInput}
                      fontSize={16}
                      value={streetName}
                      onChange={(e) => {
                        setStreetName(e.target.value)
                      }}
                      maxWidth={'21rem'}
                    />
                  ) : (
                    <IFText
                      className={Styles.textInfo}
                      style={{ color: Colors.text }}
                    >
                      {streetName}
                    </IFText>
                  )}

                  <IFText
                    className={Styles.textTitle}
                    style={{ color: Colors.text }}
                  >
                    {t('StationDetailsModal.City')}
                  </IFText>
                  {isEditing ? (
                    <div className={Styles.cityInput}>
                      <FormControl
                        className={Classes.root}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <SelectPicker
                          size="sm"
                          preventOverflow={true}
                          menuStyle={{
                            color: Colors.text,
                            zIndex: 1500,
                          }}
                          appearance="subtle"
                          searchable={true}
                          cleanable={false}
                          onSelect={(value) => setCity(value)}
                          data={citiesOptions}
                          value={city}
                          className={Styles.selectPicker}
                        />
                      </FormControl>
                      <div className={Styles.addCityIcon}>
                        <Icon
                          className={Styles.addCityButton}
                          sx={{ fontSize: 20, color: Colors.black }}
                          component={AddIcon}
                          onClick={() => addCityModalRef.current.show()}
                        />
                      </div>
                    </div>
                  ) : (
                    <IFText
                      className={Styles.textInfo}
                      style={{ color: Colors.text }}
                    >
                      {city}
                    </IFText>
                  )}
                  <IFText
                    className={Styles.textTitle}
                    style={{ color: Colors.text }}
                  >
                    {t('StationDetailsModal.Coordinates')}
                  </IFText>
                  {isEditing ? (
                    <IFTextInput
                      style={{ color: Colors.text }}
                      className={Styles.textInput}
                      value={coordinates}
                      fontSize={16}
                      onChange={(e) => {
                        setCoordinates(e.target.value)
                      }}
                      maxWidth={'21rem'}
                    />
                  ) : (
                    <IFText
                      style={{ color: Colors.text }}
                      className={Styles.textInfo}
                    >
                      {coordinates}
                    </IFText>
                  )}
                  <IFText
                    className={Styles.textTitle}
                    style={{ color: Colors.text }}
                  >
                    {t('StationDetailsModal.Access')}
                  </IFText>
                  <IFToggle
                    ref={isPrivateAccessRef}
                    height={22}
                    fontSize={12}
                    checkedText={t('StationDetailsModal.Public')}
                    uncheckedText={t('StationDetailsModal.Private')}
                    readOnly={!isEditing}
                    defaultChecked={!isPrivateAccess}
                    onChange={(value) => {
                      setIsPrivateAccessValue(!value)
                    }}
                  />
                </div>
                <div style={{ flexGrow: 2.5, flexShrink: 0 }}>
                  <IFMap
                    style={{ height: '100%' }}
                    latitude={coordinates.replaceAll(' ', '').split(',')[0]}
                    longitude={coordinates.replaceAll(' ', '').split(',')[1]}
                    connectors={connectors}
                    chargePoints={chargePoints}
                  />
                </div>
              </div>
              <div
                className={Styles.actionsContainer}
                style={
                  tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_EDIT_STATION,
                  )
                    ? { borderLeft: `1px solid ${Colors.DividerColor}` }
                    : {}
                }
              >
                {isEditing ? (
                  <div className={Styles.buttonsContainer}>
                    {(!chargePoints || chargePoints.length === 0) && [
                      <IFTooltipIconsLoading
                        key={'IFTooltipIconsLoadingStationDetailsModal.Delete'}
                        onClick={() => confirmDeleteDialog.current.show()}
                        tooltipPlacement="left"
                        title={t('StationDetailsModal.Delete')}
                        Icon={IFsvg.Delete}
                        FilledIcon={IFsvg.Delete}
                        iconColor={Colors.red}
                        iconBackgroundColor={Colors.red}
                        isDead={false}
                        isLoading={false}
                        size={24}
                        iconClassname={Styles.tooltipIcons}
                      />,
                      <div
                        style={{ backgroundColor: Colors.DividerColor }}
                        className={Styles.iconsDivider}
                      />,
                    ]}
                    <IFTooltipIconsLoading
                      key={'IFTooltipIconsLoadingStationDetailsModal.Cancel'}
                      onClick={() => {
                        setIsEditing(false)
                        resetValues()
                      }}
                      tooltipPlacement="left"
                      title={t('StationDetailsModal.Cancel')}
                      Icon={IFsvg.Cancel}
                      FilledIcon={IFsvg.Cancel}
                      iconColor={Colors.primary}
                      iconBackgroundColor={Colors.primary}
                      isDead={false}
                      isLoading={false}
                      size={24}
                      iconClassname={Styles.tooltipIcons}
                    />
                    <IFTooltipIconsLoading
                      key={'IFTooltipIconsLoadingStationDetailsModal.Done'}
                      onClick={() => {
                        handleDoneRef.current()
                      }}
                      tooltipPlacement="left"
                      title={t('StationDetailsModal.Done')}
                      Icon={IFsvg.Done}
                      FilledIcon={IFsvg.Done}
                      iconColor={Colors.primary}
                      iconBackgroundColor={Colors.primary}
                      isDead={
                        (validator.isEmpty(stationName) ||
                          validator.isEmpty(streetName) ||
                          validator.isEmpty(city) ||
                          validator.isEmpty(coordinates) ||
                          !(
                            coordinates.replaceAll(' ', '').split(',')[0] &&
                            validator.isFloat(
                              coordinates.replaceAll(' ', '').split(',')[0],
                            ) &&
                            coordinates.replaceAll(' ', '').split(',')[1] &&
                            validator.isFloat(
                              coordinates.replaceAll(' ', '').split(',')[1],
                            )
                          ) ||
                          (initialStationName === stationName &&
                            initialStreetName === streetName &&
                            initialCity === city &&
                            initialCoordinates === coordinates &&
                            initialIsPrivateAccess === isPrivateAccessValue)) &&
                        updateStationRequestState !== RequestState.LOADING
                      }
                      isLoading={
                        updateStationRequestState === RequestState.LOADING
                      }
                      size={24}
                      iconClassname={Styles.tooltipIcons}
                      animationDisabled={false}
                    />
                  </div>
                ) : (
                  <div className={Styles.buttonsContainer}>
                    {tenantUser.permissions.includes(
                      InfinityEnums.TenantUserPermission.CAN_EDIT_STATION,
                    ) && (
                      <IFTooltipIconsLoading
                        key={'IFTooltipIconsLoadingStationDetailsModal.Edit'}
                        onClick={() => setIsEditing(true)}
                        tooltipPlacement="left"
                        title={t('StationDetailsModal.Edit')}
                        isDead={false}
                        Icon={IFsvg.Edit}
                        FilledIcon={IFsvg.Edit}
                        iconColor={Colors.primary}
                        size={24}
                        iconClassname={Styles.tooltipIcons}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <IFDialog
              ref={confirmDeleteDialog}
              title={t('StationDetailsModal.DeleteDialogTitle')}
              subtitle={t('StationDetailsModal.DeleteDialogSubtitle')}
              onAction={() => deleteStation(index)}
              actionButtonIcon={
                <Icon
                  sx={{ width: '18px !important', height: '18px !important' }}
                  component={DeleteOutlineIcon}
                />
              }
              actionButtonText={t('StationDetailsModal.DeleteButtonText')}
              actionButtonColor={Colors.red}
              confirmationText={name ? name : ''}
              isLoading={deleteStationRequestState === RequestState.LOADING}
            />
          </Fragment>
        </IFModal>
        <AddCityModal ref={addCityModalRef} />
      </Fragment>
    )
  },
)
StationDetailsModal.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  connectors: PropTypes.arrayOf(PropTypes.object).isRequired,
  chargePoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.object.isRequired,
  updateStationRequestState: PropTypes.number,
  updateStation: PropTypes.func,
  tenantUser: PropTypes.object,
  deleteStationRequestState: PropTypes.number,
  deleteStation: PropTypes.func,
}
function mapDispatchToProps(dispatch) {
  return {
    updateStation: (index, station) =>
      dispatch(StationActions.updateStation(index, station)),
    deleteStation: (index) => dispatch(StationActions.deleteStation(index)),
  }
}

const mapStateToProps = (state) => ({
  updateStationRequestState:
    StationSelectors.getUpdateStationRequestState(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  deleteStationRequestState:
    StationSelectors.getDeleteStationRequestState(state),
  cities: StationSelectors.getCities(state),
  stationsFilter: StationSelectors.getStationsFilter(state),
  addCityRequestState: StationSelectors.getAddCityRequestState(state),
  deleteStationEmitterState:
    StationSelectors.getDeleteStationEmitterState(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(StationDetailsModal)
