import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { ChargePointTypes } from './Actions'
import RequestState from 'Enums/RequestState'
import history from 'history/browser'
import IFFilterType from 'Enums/IFFilterType'
import InfinityEnums from 'Enums/InfinityEnums'
import { filterBestMatch } from '../../Utils/SortFunctions'
import {
  chargePointLogFilter,
  chargePointTransactionFilter,
  chargePointStatisticsFilter,
} from 'Constants/Filters'

export const fetchChargePointListLoading = (state) => {
  return {
    ...state,
    fetchChargePointListRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointListSuccess = (
  state,
  { chargePoints, stationsFilter },
) => {
  let currentPath = history.location.pathname
  const index = currentPath.indexOf('station')
  let chargePointIndex = 0

  if (index !== -1) {
    const slashArray = history.location.pathname.split('/')
    const chargePointId = slashArray[slashArray.length - 1]

    chargePointIndex = chargePoints.findIndex(
      (chargePoint) => chargePointId === chargePoint._id,
    )
  }

  if (stationsFilter) {
    let isEmpty = true
    for (const filter of stationsFilter) {
      if (filter.value.length !== 0) {
        isEmpty = false
        break
      }
    }

    if (!isEmpty && chargePoints.length > 1) {
      chargePointIndex = filterBestMatch(stationsFilter, chargePoints)
    }
  }

  return {
    ...state,
    chargePoints: [...chargePoints],
    fetchChargePointListRequestState: RequestState.SUCCEEDED,
    selectedIndex: chargePoints.length !== 0 ? chargePointIndex : null,
  }
}

export const fetchChargePointListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointListRequestState: requestState,
  }
}

export const fetchChargePointLoading = (state) => {
  return {
    ...state,
    fetchChargePointRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointSuccess = (state, { chargePoint }) => {
  let newChargePoints = state.chargePoints
  let index = newChargePoints.findIndex((cp) => cp._id === chargePoint._id)
  if (index !== -1) {
    newChargePoints[index] = chargePoint
  }
  return {
    ...state,
    fetchChargePointRequestState: RequestState.SUCCEEDED,
    chargePoints: [...newChargePoints],
  }
}

export const fetchChargePointFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointRequestState: requestState,
  }
}
export const clearChargePoints = (state) => {
  return {
    ...state,
    chargePoints: [],
  }
}

export const setChargePointSelectedIndex = (state, { index }) => {
  return {
    ...state,
    selectedIndex: index,
  }
}

export const resetChargePointState = (state) => {
  return {
    ...state,
    chargePointLogPaginationOffset: 0,
    chargePointTransactionPaginationOffset: 0,
    fetchChargePointListRequestState: RequestState.UNINITIALIZED,
    fetchChargePointRequestState: RequestState.UNINITIALIZED,
    updateChargePointRequestState: RequestState.UNINITIALIZED,
    fetchChargePointLogListRequestState: RequestState.UNINITIALIZED,
    fetchChargePointTransactionListRequestState: RequestState.UNINITIALIZED,
    fetchChargePointConfigListRequestState: RequestState.UNINITIALIZED,
    updateChargePointConfigRequestState: RequestState.UNINITIALIZED,
    deleteChargePointRequestState: RequestState.UNINITIALIZED,
    addChargePointRequestState: RequestState.UNINITIALIZED,
    resetChargePointRequestState: RequestState.UNINITIALIZED,
    moveChargePointRequestState: RequestState.UNINITIALIZED,
    deleteMovedChargePointRequestState: RequestState.UNINITIALIZED,
    chargePointUploadDiagnostics: [],
  }
}

export const setChargePointLogPaginationOffset = (state, { offset }) => {
  return {
    ...state,
    chargePointLogPaginationOffset: offset,
  }
}

export const setChargePointTransactionPaginationOffset = (
  state,
  { offset },
) => {
  return {
    ...state,
    chargePointTransactionPaginationOffset: offset,
  }
}

export const updateChargePointLoading = (state) => {
  return {
    ...state,
    updateChargePointRequestState: RequestState.LOADING,
  }
}

export const updateChargePointSuccess = (
  state,
  { index, updatedChargePoint },
) => {
  let newChargePoints = [...state.chargePoints]
  newChargePoints[index] = updatedChargePoint
  return {
    ...state,
    chargePoints: newChargePoints,
    updateChargePointRequestState: RequestState.SUCCEEDED,
  }
}

export const updateChargePointFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateChargePointRequestState: requestState,
  }
}

export const moveChargePointLoading = (state) => {
  return {
    ...state,
    moveChargePointRequestState: RequestState.LOADING,
  }
}

export const moveChargePointSuccess = (
  state,
  { index, updatedChargePoint },
) => {
  return {
    ...state,
    moveChargePointRequestState: RequestState.SUCCEEDED,
  }
}

export const moveChargePointFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    moveChargePointRequestState: requestState,
  }
}

export const fetchChargePointLogListLoading = (state) => {
  return {
    ...state,
    fetchChargePointLogListRequestState: RequestState.LOADING,
  }
}

export const prependChargePointLogList = (state, { chargePointLogs }) => {
  let appendCount = 0
  let tempLogs = []
  for (let chargePointLog of chargePointLogs) {
    tempLogs.push(chargePointLog)
    if (chargePointLog.requestMessage) appendCount++
    if (chargePointLog.responseMessage) appendCount++
  }
  if (tempLogs.length > 1) tempLogs = tempLogs.reverse()
  return {
    ...state,
    chargePointLogs: [...tempLogs, ...state.chargePointLogs],
    chargePointLogsFirstItemIndex:
      state.chargePointLogsFirstItemIndex - appendCount,
    bufferedChargePointLogs: [],
  }
}

export const appendBufferChargePointLogList = (state, { chargePointLogs }) => {
  return {
    ...state,
    bufferedChargePointLogs: [
      ...chargePointLogs,
      ...state.bufferedChargePointLogs,
    ],
  }
}

export const fetchChargePointLogListSuccess = (
  state,
  { offset, chargePointLogs, nextOffset },
) => {
  let result = []
  for (let j = 0; j < chargePointLogs.length; j++) {
    if (
      !state.chargePointLogs.some(
        (chargePointLog) => chargePointLog._id === chargePointLogs[j]._id,
      )
    ) {
      result.push(chargePointLogs[j])
    }
  }
  if (offset === 0) {
    return {
      ...state,
      chargePointLogs: [...chargePointLogs],
      chargePointLogPaginationOffset: nextOffset,
      fetchChargePointLogListRequestState: RequestState.SUCCEEDED,
    }
  } else {
    return {
      ...state,
      chargePointLogs: [...state.chargePointLogs, ...result],
      chargePointLogPaginationOffset: nextOffset,
      fetchChargePointLogListRequestState: RequestState.SUCCEEDED,
    }
  }
}

export const fetchChargePointLogListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointLogListRequestState: requestState,
  }
}

export const clearChargePointLogs = (state) => {
  return {
    ...state,
    chargePointLogs: [],
    chargePointLogsFirstItemIndex: 1000000,
  }
}

export const setChargePointLogsFilter = (state, { newFilter }) => {
  return {
    ...state,
    chargePointLogFilter: newFilter,
  }
}

export const fetchChargePointTransactionListLoading = (state) => {
  return {
    ...state,
    fetchChargePointTransactionListRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointTransactionListSuccess = (
  state,
  { offset, chargePointTransactions, nextOffset },
) => {
  let result = []
  for (let j = 0; j < chargePointTransactions.length; j++) {
    if (
      !state.chargePointTransactions.some(
        (chargePointTransaction) =>
          chargePointTransaction._id === chargePointTransactions[j]._id,
      )
    ) {
      result.push(chargePointTransactions[j])
    }
  }
  if (offset === 0) {
    return {
      ...state,
      chargePointTransactions: [...chargePointTransactions],
      chargePointTransactionPaginationOffset: nextOffset,
      fetchChargePointTransactionListRequestState: RequestState.SUCCEEDED,
    }
  } else {
    return {
      ...state,
      chargePointTransactions: [...state.chargePointTransactions, ...result],
      chargePointTransactionPaginationOffset: nextOffset,
      fetchChargePointTransactionListRequestState: RequestState.SUCCEEDED,
    }
  }
}

export const fetchChargePointTransactionListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointTransactionListRequestState: requestState,
  }
}

export const clearChargePointTransactions = (state) => {
  return {
    ...state,
    chargePointTransactions: [],
  }
}

export const setChargePointTransactionsFilter = (state, { newFilter }) => {
  return {
    ...state,
    chargePointTransactionFilter: newFilter,
  }
}

export const fetchChargePointConfigListLoading = (state) => {
  return {
    ...state,
    fetchChargePointConfigListRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointConfigListSuccess = (state, { data }) => {
  return {
    ...state,
    chargePointConfigs: { ...data.configs },
    chargePointConfigsLastUpdated: data.updatedAt,
    fetchChargePointConfigListRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointConfigListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    chargePointConfigs: error?.data?.configs ? { ...error?.data?.configs } : {},
    chargePointConfigsLastUpdated: error?.data?.updatedAt
      ? error?.data?.updatedAt
      : '',
    fetchChargePointConfigListRequestState: requestState,
  }
}

export const clearChargePointConfigs = (state) => {
  return {
    ...state,
    chargePointConfigs: [],
  }
}

export const setChargePointFilterText = (state, { filter }) => {
  return {
    ...state,
    chargePointFilterText: filter,
  }
}

export const updateChargePointConfigLoading = (state) => {
  return {
    ...state,
    updateChargePointConfigRequestState: RequestState.LOADING,
  }
}

export const updateChargePointConfigSuccess = (state, { key, value }) => {
  let newConfigList = state.chargePointConfigs
  newConfigList[key]['value'] = value
  return {
    ...state,
    chargePointConfigs: newConfigList,
    updateChargePointConfigRequestState: RequestState.SUCCEEDED,
  }
}

export const updateChargePointConfigFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateChargePointConfigRequestState: requestState,
  }
}

export const deleteChargePointLoading = (state) => {
  return {
    ...state,
    deleteChargePointRequestState: RequestState.LOADING,
  }
}

export const deleteChargePointSuccess = (state, { chargePointId }) => {
  if (
    state.chargePoints.some((chargePoint) => chargePoint._id === chargePointId)
  ) {
    let newChargePoints = state.chargePoints.filter(
      (chargePoint) => chargePoint._id !== chargePointId,
    )

    if (newChargePoints.length === 0) {
      let currentPath = history.location.pathname
      const index = currentPath.indexOf('station')
      currentPath = currentPath.substring(0, index - 1)
      history.push({ pathname: currentPath })
    }

    return {
      ...state,
      chargePoints: newChargePoints,
      selectedIndex: 0,
      deleteChargePointRequestState: RequestState.SUCCEEDED,
    }
  } else if (state.deleteChargePointRequestState === RequestState.LOADING)
    return { ...state, deleteChargePointRequestState: RequestState.SUCCEEDED }
  else return { ...state }
}

export const deleteMovedChargePoint = (state, { chargePointId }) => {
  if (
    state.chargePoints.some((chargePoint) => chargePoint._id === chargePointId)
  ) {
    let newChargePoints = state.chargePoints.filter(
      (chargePoint) => chargePoint._id !== chargePointId,
    )

    return {
      ...state,
      chargePoints: newChargePoints,
      selectedIndex: 0,
      deleteMovedChargePointRequestState: RequestState.SUCCEEDED,
    }
  } else return { ...state }
}

export const deleteChargePointFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteChargePointRequestState: requestState,
  }
}

export const addChargePointLoading = (state) => {
  return {
    ...state,
    addChargePointRequestState: RequestState.LOADING,
  }
}

export const addChargePointSuccess = (state, { newChargePoint }) => {
  if (
    state.chargePoints.some(
      (chargePoint) => chargePoint._id === newChargePoint._id,
    )
  ) {
    if (state.addChargePointRequestState === RequestState.LOADING) {
      const index = state.chargePoints.findIndex(
        (chargePoint) => chargePoint._id === newChargePoint._id,
      )
      return {
        ...state,
        addChargePointRequestState: RequestState.SUCCEEDED,
        chargePointLogPaginationOffset: 0,
        selectedIndex: index,
      }
    } else return { ...state }
  } else {
    let newChargePoints = [...state.chargePoints, newChargePoint]
    newChargePoints = newChargePoints.sort((a, b) => {
      let nameA = a.name.toLowerCase()
      let nameB = b.name.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
    const index = newChargePoints.findIndex(
      (chargePoint) => chargePoint._id === newChargePoint._id,
    )
    return {
      ...state,
      chargePoints: newChargePoints,
      addChargePointRequestState: RequestState.SUCCEEDED,
      chargePointLogPaginationOffset: 0,
      ...(state.chargePoints.length === 0
        ? { selectedIndex: 0 }
        : { selectedIndex: index }),
    }
  }
}

export const addChargePointFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addChargePointRequestState: requestState,
  }
}
export const resetChargePointLoading = (state) => {
  return {
    ...state,
    resetChargePointRequestState: RequestState.LOADING,
  }
}
export const resetChargePointSuccess = (state) => {
  return {
    ...state,
    resetChargePointRequestState: RequestState.SUCCEEDED,
  }
}

export const resetChargePointFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    resetChargePointRequestState: requestState,
  }
}

export const emitterUpdateChargePoint = (state, { args: { data } }) => {
  const { uid, chargePoint } = data
  let newChargePoints = [...state.chargePoints]
  let index = newChargePoints.findIndex(
    (chargePoint) => chargePoint.uid === uid,
  )

  let newChargePointTemp = {
    ...newChargePoints[index],
  }
  if (index !== -1) {
    if (chargePoint.name) {
      newChargePointTemp['name'] = chargePoint.name
      newChargePoints[index]['name'] = chargePoint.name
    }
    if (chargePoint.connectors) {
      let index = newChargePoints.findIndex(
        (chargePoint) => chargePoint.uid === uid,
      )
      newChargePoints[index]['connectors'].forEach((connector) => {
        if (connector.session) {
          let connectorIndex = chargePoint.connectors.findIndex(
            (newChargePointConnector) =>
              newChargePointConnector.uid === connector.uid,
          )
          if (index !== -1) {
            chargePoint.connectors[connectorIndex].session = connector.session
          }
        }
      })
      newChargePointTemp['connectors'] = chargePoint.connectors
      newChargePoints[index]['connectors'] = chargePoint.connectors
    }
    if (chargePoint.isOnline === true || chargePoint.isOnline === false) {
      newChargePointTemp['isOnline'] = chargePoint.isOnline
      newChargePoints[index]['isOnline'] = chargePoint.isOnline
    }
    if (chargePoint.isPrivate === true || chargePoint.isPrivate === false) {
      newChargePointTemp['isPrivate'] = chargePoint.isPrivate
      newChargePoints[index]['isPrivate'] = chargePoint.isPrivate
    }
    if (chargePoint.isHidden === true || chargePoint.isHidden === false) {
      newChargePointTemp['isHidden'] = chargePoint.isHidden
      newChargePoints[index]['isHidden'] = chargePoint.isHidden
    }
  } else return { ...state }
  if (
    chargePoint.isActivated === true &&
    !newChargePoints[index]['isActivated']
  ) {
    newChargePointTemp['isActivated'] = true
    newChargePoints[index] = newChargePointTemp
  }

  return {
    ...state,
    chargePoints: newChargePoints,
  }
}

export const emitterAddChargePoint = (state, { args: { data } }) => {
  if (state.chargePoints.some((chargePoint) => chargePoint._id === data._id)) {
    return { ...state }
  } else {
    if (state.chargePoints.length === 0) {
      let currentPath = history.location.pathname
      const index = currentPath.indexOf('station')
      if (index === -1) {
        currentPath = currentPath + `/stations/${data._id}`
        history.push({ pathname: currentPath })
      }
    }
    let selectedChargePoint = state.chargePoints[state.selectedIndex]
    let newChargePoints = [...state.chargePoints, data]
    newChargePoints = newChargePoints.sort((a, b) => {
      let nameA = a.name.toLowerCase()
      let nameB = b.name.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
    const index = newChargePoints.findIndex(
      (chargePoint) => chargePoint._id === selectedChargePoint?._id,
    )
    return {
      ...state,
      chargePoints: newChargePoints,
      ...(state.chargePoints.length === 0
        ? { selectedIndex: 0 }
        : { selectedIndex: index }),
    }
  }
}

export const emitterDeleteChargePoint = (state, { args: { data } }) => {
  if (state.chargePoints.some((chargePoint) => chargePoint.uid === data)) {
    const index = state.chargePoints.findIndex(
      (chargePoint) => chargePoint.uid === data,
    )
    let currentlySelectedFlag = false
    if (index !== -1 && index === state.selectedIndex)
      currentlySelectedFlag = true
    let newChargePoints = state.chargePoints.filter(
      (chargePoint) => chargePoint.uid !== data,
    )

    return {
      ...state,
      chargePoints: newChargePoints,
      ...(currentlySelectedFlag || newChargePoints.length === 1
        ? { selectedIndex: 0 }
        : {}),
      deleteMovedChargePointRequestState: RequestState.SUCCEEDED,
    }
  } else return { ...state }
}

export const resetFilters = (state) => {
  return {
    ...state,
    chargePointLogFilter: chargePointLogFilter,
    chargePointTransactionFilter: chargePointTransactionFilter,
    chargePointStatisticsFilter: chargePointStatisticsFilter,
  }
}
export const emitterUpdateConnectorSessionStatus = (
  state,
  { args: { data } },
) => {
  let newChargePoint = state.chargePoints[state.selectedIndex]
  let newChargePoints = state.chargePoints
  let index = newChargePoint.connectors.findIndex(
    (connector) => connector.uid === data.connectorUid,
  )
  if (index !== -1) {
    if (data.status === InfinityEnums.ChargingSessionStatus.ENDED) {
      newChargePoint.connectors[index].session = undefined
      newChargePoints[state.selectedIndex] = newChargePoint
    } else {
      newChargePoint.connectors[index].session = data
      newChargePoints[state.selectedIndex] = newChargePoint
    }
  } else return { ...state }
  return {
    ...state,
    chargePoints: [...newChargePoints],
  }
}

export const clearChargePointCacheLoading = (state) => {
  return {
    ...state,
    clearChargePointCacheRequestState: RequestState.LOADING,
  }
}
export const clearChargePointCacheSuccess = (state) => {
  return {
    ...state,
    clearChargePointCacheRequestState: RequestState.SUCCEEDED,
  }
}

export const clearChargePointCacheFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    clearChargePointCacheRequestState: requestState,
  }
}

export const changeChargePointAvailabilityLoading = (state) => {
  return {
    ...state,
    changeChargePointAvailabilityRequestState: RequestState.LOADING,
  }
}

export const changeChargePointAvailabilitySuccess = (state) => {
  return {
    ...state,
    changeChargePointAvailabilityRequestState: RequestState.SUCCEEDED,
  }
}

export const changeChargePointAvailabilityFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    changeChargePointAvailabilityRequestState: requestState,
  }
}

export const updateChargePointFirmwareLoading = (state) => {
  return {
    ...state,
    updateChargePointFirmwareRequestState: RequestState.LOADING,
  }
}

export const updateChargePointFirmwareSuccess = (state) => {
  return {
    ...state,
    updateChargePointFirmwareRequestState: RequestState.SUCCEEDED,
  }
}

export const updateChargePointFirmwareFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateChargePointFirmwareRequestState: requestState,
  }
}

export const fetchChargePointUploadDiagnosticsLoading = (state) => {
  return {
    ...state,
    fetchChargePointUploadDiagnosticsRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointUploadDiagnosticsSuccess = (
  state,
  { uploadDiagnostics },
) => {
  return {
    ...state,
    chargePointUploadDiagnostics: [...uploadDiagnostics],
    fetchChargePointUploadDiagnosticsRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointSubscriptionsLoading = (state) => {
  return {
    ...state,
    fetchChargePointSubscriptionsRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointSubscriptionsSuccess = (
  state,
  { subscriptions },
) => {
  return {
    ...state,
    chargePointSubscriptions: subscriptions,
    fetchChargePointSubscriptionsRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointSubscriptionsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointSubscriptionsRequestState: requestState,
  }
}

export const deleteChargePointSubscriptionsLoading = (state) => {
  return {
    ...state,
    deleteChargePointSubscriptionsRequestState: RequestState.LOADING,
  }
}

export const deleteChargePointSubscriptionsSuccess = (
  state,
  { subscriptions },
) => {
  const chargePointSubscriptions = state.chargePointSubscriptions
  const filteredSubscriptions = chargePointSubscriptions.filter((subSchema) => {
    return subscriptions.indexOf(subSchema.subscription._id) === -1
  })
  return {
    ...state,
    chargePointSubscriptions: filteredSubscriptions,
    deleteChargePointSubscriptionsRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteChargePointSubscriptionsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteChargePointSubscriptionsRequestState: requestState,
  }
}

export const fetchChargePointAccessGroupsLoading = (state) => {
  return {
    ...state,
    fetchChargePointAccessGroupsRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointAccessGroupsSuccess = (
  state,
  { accessGroups },
) => {
  return {
    ...state,
    chargePointAccessGroups: [...accessGroups],
    fetchChargePointAccessGroupsRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointAccessGroupsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointAccessGroupsRequestState: requestState,
  }
}

export const addChargePointSubscriptionsLoading = (state) => {
  return {
    ...state,
    addChargePointSubscriptionsRequestState: RequestState.LOADING,
  }
}

export const addChargePointSubscriptionsSuccess = (
  state,
  { subscriptions },
) => {
  return {
    ...state,
    chargePointSubscriptions: [
      ...new Set(state.chargePointSubscriptions.concat(subscriptions)),
    ],
    addChargePointSubscriptionsRequestState: RequestState.SUCCEEDED,
  }
}

export const addChargePointSubscriptionsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addChargePointSubscriptionsRequestState: requestState,
  }
}

export const fetchChargePointUploadDiagnosticsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointUploadDiagnosticsRequestState: requestState,
  }
}

export const requestChargePointUploadDiagnosticsLoading = (state) => {
  return {
    ...state,
    requestChargePointUploadDiagnosticsRequestState: RequestState.LOADING,
  }
}

export const requestChargePointUploadDiagnosticsSuccess = (
  state,
  { newUploadDiagnostics },
) => {
  const oldUploadDiagnostics = state.chargePointUploadDiagnostics
  return {
    ...state,
    chargePointUploadDiagnostics: [
      newUploadDiagnostics,
      ...oldUploadDiagnostics,
    ],
    requestChargePointUploadDiagnosticsRequestState: RequestState.SUCCEEDED,
  }
}

export const requestChargePointUploadDiagnosticsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    requestChargePointUploadDiagnosticsRequestState: requestState,
  }
}

export const updateChargePointFirmwareUpload = (state, { progress }) => {
  return {
    ...state,
    chargePointFirmwareUploadProgress: progress,
  }
}

export const diagnosticsStatusNotificationLoading = (state) => {
  return {
    ...state,
    diagnosticsStatusNotificationRequestState: RequestState.LOADING,
  }
}

export const diagnosticsStatusNotificationSuccess = (state) => {
  return {
    ...state,
    diagnosticsStatusNotificationRequestState: RequestState.SUCCEEDED,
  }
}

export const emitterUpdateChargePointDiagnostics = (
  state,
  { args: { data } },
) => {
  const { status } = data
  let chargePointDiagnostics = state.chargePointUploadDiagnostics
  chargePointDiagnostics[0].status = status
  return {
    ...state,
    chargePointUploadDiagnostics: [...chargePointDiagnostics],
  }
}

export const diagnosticsStatusNotificationFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    diagnosticsStatusNotificationRequestState: requestState,
  }
}

export const fetchChargePointPrivacyScheduleLoading = (state) => {
  return {
    ...state,
    fetchChargePointPrivacyScheduleRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointPrivacyScheduleSuccess = (
  state,
  { privacySchedule },
) => {
  return {
    ...state,
    chargePointPrivacySchedule: privacySchedule,
    fetchChargePointPrivacyScheduleRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointPrivacyScheduleFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointPrivacyScheduleRequestState: requestState,
  }
}

export const deleteChargePointPrivacyRulesLoading = (state) => {
  return {
    ...state,
    deleteChargePointPrivacyRulesRequestState: RequestState.LOADING,
  }
}

export const deleteChargePointPrivacyRulesSuccess = (
  state,
  { privacyRules },
) => {
  const chargePointPrivacySchedule = state.chargePointPrivacySchedule
  const filteredPrivacyRules = chargePointPrivacySchedule.filter(
    (ruleObject) => {
      return privacyRules.indexOf(ruleObject.rule._id) === -1
    },
  )
  return {
    ...state,
    chargePointPrivacySchedule: filteredPrivacyRules,
    deleteChargePointPrivacyRulesRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteChargePointPrivacyRulesFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteChargePointPrivacyRulesRequestState: requestState,
  }
}
export const addChargePointPrivacyRulesLoading = (state) => {
  return {
    ...state,
    addChargePointPrivacyRulesRequestState: RequestState.LOADING,
  }
}

export const addChargePointPrivacyRulesSuccess = (state, { privacyRules }) => {
  return {
    ...state,
    chargePointPrivacySchedule: [
      ...new Set(state.chargePointPrivacySchedule.concat(privacyRules)),
    ],
    addChargePointPrivacyRulesRequestState: RequestState.SUCCEEDED,
  }
}

export const addChargePointPrivacyRulesFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addChargePointPrivacyRulesRequestState: requestState,
  }
}
export const addChargePointAccessGroupsBulkLoading = (state) => {
  return {
    ...state,
    addChargePointAccessGroupsBulkRequestState: RequestState.LOADING,
  }
}
export const clearChargePointAccessGroups = (state) => {
  return {
    ...state,
    chargePointAccessGroups: [],
  }
}

export const deleteChargePointAccessGroupsBulkLoading = (state) => {
  return {
    ...state,
    deleteChargePointAccessGroupsBulkRequestState: RequestState.LOADING,
  }
}

export const deleteChargePointAccessGroupsBulkSuccess = (state, { ids }) => {
  let newAccessGroups = state.chargePointAccessGroups
  for (const id of ids) {
    newAccessGroups = newAccessGroups.filter((group) => group._id !== id)
  }

  return {
    ...state,
    chargePointAccessGroups: [...newAccessGroups],
    deleteChargePointAccessGroupsBulkRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteChargePointAccessGroupsBulkFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteChargePointAccessGroupsBulkRequestState: requestState,
  }
}

export const addChargePointAccessGroupsBulkSuccess = (
  state,
  { accessGroups },
) => {
  return {
    ...state,
    chargePointAccessGroups: [
      ...state.chargePointAccessGroups,
      ...accessGroups,
    ],
    addChargePointAccessGroupsBulkRequestState: RequestState.SUCCEEDED,
  }
}

export const addChargePointAccessGroupsBulkFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addChargePointAccessGroupsBulkRequestState: requestState,
  }
}

export const resetChargePointSpecifications = (state) => {
  return {
    ...state,
    chargePointSubscriptions: [],
    chargePointPrivacySchedule: [],
    chargePointAccessGroups: [],
  }
}
export const setIsChargePointLogsLive = (state, { flag }) => {
  return {
    ...state,
    isChargePointLogsLive: flag,
  }
}

export const fetchChargePointUptimeStatisticsLoading = (state) => {
  return {
    ...state,
    fetchChargePointUptimeStatisticsRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointUptimeStatisticsSuccess = (
  state,
  { cpUptimeStatistics },
) => {
  return {
    ...state,
    chargePointUptimeData: cpUptimeStatistics,
    fetchChargePointUptimeStatisticsRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointUptimeStatisticsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointUptimeStatisticsRequestState: requestState,
  }
}

export const setChargePointStatisticsFilter = (state, { newFilter }) => {
  return {
    ...state,
    chargePointStatisticsFilter: newFilter,
  }
}

export const fetchConnectorsUtilizationLoading = (state) => {
  return {
    ...state,
    fetchConnectorsUtilizationRequestState: RequestState.LOADING,
  }
}

export const fetchConnectorsUtilizationSuccess = (
  state,
  { connectorsUtilization },
) => {
  return {
    ...state,
    connectorsUtilizationData: connectorsUtilization,
    fetchConnectorsUtilizationRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchConnectorsUtilizationFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchConnectorsUtilizationRequestState: requestState,
  }
}

export const fetchChargePointSubscribedAlertsLoading = (state) => {
  return {
    ...state,
    fetchChargePointSubscribedAlertsRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointSubscribedAlertsSuccess = (state, { alerts }) => {
  return {
    ...state,
    subscribedAlerts: [...alerts],
    fetchChargePointSubscribedAlertsRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointSubscribedAlertsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointSubscribedAlertsRequestState: requestState,
  }
}

export const fetchChargePointAlertsLoading = (state) => {
  return {
    ...state,
    fetchChargePointAlertsRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointAlertsSuccess = (state, { alerts }) => {
  return {
    ...state,
    alerts: [...alerts],
    fetchChargePointAlertsRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointAlertsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointAlertsRequestState: requestState,
  }
}

export const updateChargePointAlertsLoading = (state) => {
  return {
    ...state,
    updateChargePointAlertsRequestState: RequestState.LOADING,
  }
}

export const updateChargePointAlertsSuccess = (
  state,
  { data, alertChanges },
) => {
  let subscribedAlerts = state.subscribedAlerts
  let alerts = state.alerts
  let chargePoint = state.chargePoints[state.selectedIndex]

  for (const [key, value] of Object.entries(alertChanges)) {
    const subscribedAlertIndex = subscribedAlerts.findIndex(
      (alert) => alert._id === key,
    )
    if (subscribedAlertIndex !== -1) {
      if ('isGlobal' in value) {
        if (value.isGlobal) {
          const newExcludeArray = subscribedAlerts[
            subscribedAlertIndex
          ].exclude.filter((uid) => uid !== chargePoint.uid)
          subscribedAlerts[subscribedAlertIndex].exclude = newExcludeArray
        } else {
          subscribedAlerts[subscribedAlertIndex].exclude.push(chargePoint.uid)
        }
      } else {
        if (!value.chargePoints) {
          const alertIndex = alerts.findIndex((alert) => alert._id === key)
          if (alertIndex === -1)
            alerts.push(subscribedAlerts[subscribedAlertIndex])
          subscribedAlerts.splice(subscribedAlertIndex, 1)
        }
      }
    }
  }
  if (data) subscribedAlerts = [...subscribedAlerts, ...data]
  return {
    ...state,
    subscribedAlerts: [...subscribedAlerts],
    alerts: [...alerts],
    updateChargePointAlertsRequestState: RequestState.SUCCEEDED,
  }
}

export const updateChargePointAlertsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateChargePointAlertsRequestState: requestState,
  }
}

export const fetchChargePointLocalListLoading = (state) => {
  return {
    ...state,
    fetchChargePointLocalListRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointLocalListSuccess = (state, { localList }) => {
  return {
    ...state,
    chargePointLocalList: { ...localList },
    fetchChargePointLocalListRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointLocalListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointLocalListRequestState: requestState,
  }
}
export const updateChargePointLocalListLoading = (state) => {
  return {
    ...state,
    updateChargePointLocalListRequestState: RequestState.LOADING,
  }
}

export const updateChargePointLocalListSuccess = (state, { localList }) => {
  return {
    ...state,
    updateChargePointLocalListRequestState: RequestState.SUCCEEDED,
  }
}

export const updateChargePointLocalListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateChargePointLocalListRequestState: requestState,
  }
}

export const fetchChargePointChargingProfilesLoading = (state) => {
  return {
    ...state,
    fetchChargePointChargingProfilesRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointChargingProfilesSuccess = (
  state,
  { chargingProfiles },
) => {
  return {
    ...state,
    chargingProfiles: [...chargingProfiles],
    fetchChargePointChargingProfilesRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointChargingProfilesFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointChargingProfilesRequestState: requestState,
  }
}

export const fetchChargePointCompositeScheduleLoading = (state) => {
  return {
    ...state,
    fetchChargePointCompositeScheduleRequestState: RequestState.LOADING,
  }
}

export const fetchChargePointCompositeScheduleSuccess = (
  state,
  { compositeSchedule },
) => {
  return {
    ...state,
    compositeSchedule: { ...compositeSchedule },
    fetchChargePointCompositeScheduleRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchChargePointCompositeScheduleFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchChargePointCompositeScheduleRequestState: requestState,
  }
}

export const clearChargePointChargingProfileLoading = (state) => {
  return {
    ...state,
    clearChargePointChargingProfileRequestState: RequestState.LOADING,
  }
}

export const clearChargePointChargingProfileSuccess = (
  state,
  { chargingProfileIds },
) => {
  let newChargingProfiles = state.chargingProfiles
  newChargingProfiles = newChargingProfiles.filter(
    (chargingProfile) =>
      !chargingProfileIds.includes(chargingProfile.profile.uid),
  )
  return {
    ...state,
    chargingProfiles: [...newChargingProfiles],
    clearChargePointChargingProfileRequestState: RequestState.SUCCEEDED,
  }
}

export const clearChargePointChargingProfileFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    clearChargePointChargingProfileRequestState: requestState,
  }
}

export const setChargePointChargingProfileLoading = (state) => {
  return {
    ...state,
    setChargePointChargingProfileRequestState: RequestState.LOADING,
  }
}

export const setChargePointChargingProfileSuccess = (
  state,
  { newChargingProfile },
) => {
  let chargingProfiles = state.chargingProfiles
  const index = chargingProfiles.findIndex(
    (chargingProfile) =>
      chargingProfile.profile.uid === newChargingProfile.profile.uid,
  )
  if (index === -1) chargingProfiles.push(newChargingProfile)
  return {
    ...state,
    chargingProfiles: [...chargingProfiles],
    setChargePointChargingProfileRequestState: RequestState.SUCCEEDED,
  }
}

export const setChargePointChargingProfileFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    setChargePointChargingProfileRequestState: requestState,
  }
}

export const createChargePointChargingProfileLoading = (state) => {
  return {
    ...state,
    createChargePointChargingProfileRequestState: RequestState.LOADING,
  }
}

export const createChargePointChargingProfileSuccess = (state) => {
  return {
    ...state,
    createChargePointChargingProfileRequestState: RequestState.SUCCEEDED,
  }
}

export const createChargePointChargingProfileFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    createChargePointChargingProfileRequestState: requestState,
  }
}

export const fetchConnectorTypesLoading = (state) => {
  return {
    ...state,
    fetchConnectorTypesRequestState: RequestState.LOADING,
  }
}

export const fetchConnectorTypesSuccess = (state, { filters }) => {
  return {
    ...state,
    connectorTypes: filters,
    fetchConnectorTypesRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchConnectorTypesFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchConnectorTypesRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [ChargePointTypes.FETCH_CHARGE_POINT_LIST_LOADING]:
    fetchChargePointListLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_LIST_SUCCESS]:
    fetchChargePointListSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_LIST_FAIL]: fetchChargePointListFail,
  [ChargePointTypes.CLEAR_CHARGE_POINTS]: clearChargePoints,
  [ChargePointTypes.SET_CHARGE_POINT_SELECTED_INDEX]:
    setChargePointSelectedIndex,
  [ChargePointTypes.RESET_CHARGE_POINT_STATE]: resetChargePointState,
  [ChargePointTypes.UPDATE_CHARGE_POINT_LOADING]: updateChargePointLoading,
  [ChargePointTypes.UPDATE_CHARGE_POINT_SUCCESS]: updateChargePointSuccess,
  [ChargePointTypes.UPDATE_CHARGE_POINT_FAIL]: updateChargePointFail,
  [ChargePointTypes.FETCH_CHARGE_POINT_LOG_LIST_LOADING]:
    fetchChargePointLogListLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_LOG_LIST_SUCCESS]:
    fetchChargePointLogListSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_LOG_LIST_FAIL]:
    fetchChargePointLogListFail,
  [ChargePointTypes.CLEAR_CHARGE_POINT_LOGS]: clearChargePointLogs,
  [ChargePointTypes.SET_CHARGE_POINT_LOGS_FILTER]: setChargePointLogsFilter,
  [ChargePointTypes.FETCH_CHARGE_POINT_TRANSACTION_LIST_LOADING]:
    fetchChargePointTransactionListLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_TRANSACTION_LIST_SUCCESS]:
    fetchChargePointTransactionListSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_TRANSACTION_LIST_FAIL]:
    fetchChargePointTransactionListFail,
  [ChargePointTypes.CLEAR_CHARGE_POINT_TRANSACTIONS]:
    clearChargePointTransactions,
  [ChargePointTypes.SET_CHARGE_POINT_TRANSACTIONS_FILTER]:
    setChargePointTransactionsFilter,
  [ChargePointTypes.FETCH_CHARGE_POINT_CONFIG_LIST_LOADING]:
    fetchChargePointConfigListLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_CONFIG_LIST_SUCCESS]:
    fetchChargePointConfigListSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_CONFIG_LIST_FAIL]:
    fetchChargePointConfigListFail,
  [ChargePointTypes.CLEAR_CHARGE_POINT_CONFIGS]: clearChargePointConfigs,
  [ChargePointTypes.SET_CHARGE_POINT_FILTER_TEXT]: setChargePointFilterText,
  [ChargePointTypes.UPDATE_CHARGE_POINT_CONFIG_LOADING]:
    updateChargePointConfigLoading,
  [ChargePointTypes.UPDATE_CHARGE_POINT_CONFIG_SUCCESS]:
    updateChargePointConfigSuccess,
  [ChargePointTypes.UPDATE_CHARGE_POINT_CONFIG_FAIL]:
    updateChargePointConfigFail,
  [ChargePointTypes.DELETE_CHARGE_POINT_LOADING]: deleteChargePointLoading,
  [ChargePointTypes.DELETE_CHARGE_POINT_SUCCESS]: deleteChargePointSuccess,
  [ChargePointTypes.DELETE_CHARGE_POINT_FAIL]: deleteChargePointFail,
  [ChargePointTypes.ADD_CHARGE_POINT_LOADING]: addChargePointLoading,
  [ChargePointTypes.ADD_CHARGE_POINT_SUCCESS]: addChargePointSuccess,
  [ChargePointTypes.ADD_CHARGE_POINT_FAIL]: addChargePointFail,
  [ChargePointTypes.DELETE_MOVED_CHARGE_POINT]: deleteMovedChargePoint,
  [ChargePointTypes.MOVE_CHARGE_POINT_LOADING]: moveChargePointLoading,
  [ChargePointTypes.MOVE_CHARGE_POINT_SUCCESS]: moveChargePointSuccess,
  [ChargePointTypes.MOVE_CHARGE_POINT_FAIL]: moveChargePointFail,
  [ChargePointTypes.SET_CHARGE_POINT_LOG_PAGINATION_OFFSET]:
    setChargePointLogPaginationOffset,
  [ChargePointTypes.SET_CHARGE_POINT_TRANSACTION_PAGINATION_OFFSET]:
    setChargePointTransactionPaginationOffset,
  [ChargePointTypes.RESET_CHARGE_POINT_LOADING]: resetChargePointLoading,
  [ChargePointTypes.RESET_CHARGE_POINT_SUCCESS]: resetChargePointSuccess,
  [ChargePointTypes.RESET_CHARGE_POINT_FAIL]: resetChargePointFail,
  [ChargePointTypes.EMITTER_UPDATE_CHARGE_POINT]: emitterUpdateChargePoint,
  [ChargePointTypes.EMITTER_ADD_CHARGE_POINT]: emitterAddChargePoint,
  [ChargePointTypes.EMITTER_DELETE_CHARGE_POINT]: emitterDeleteChargePoint,
  [ChargePointTypes.RESET_FILTERS]: resetFilters,
  [ChargePointTypes.EMITTER_UPDATE_CONNECTOR_SESSION_STATUS]:
    emitterUpdateConnectorSessionStatus,
  [ChargePointTypes.FETCH_CHARGE_POINT_LOADING]: fetchChargePointLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_SUCCESS]: fetchChargePointSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_FAIL]: fetchChargePointFail,
  [ChargePointTypes.CLEAR_CHARGE_POINT_CACHE_LOADING]:
    clearChargePointCacheLoading,
  [ChargePointTypes.CLEAR_CHARGE_POINT_CACHE_SUCCESS]:
    clearChargePointCacheSuccess,
  [ChargePointTypes.CLEAR_CHARGE_POINT_CACHE_FAIL]: clearChargePointCacheFail,
  [ChargePointTypes.CHANGE_CHARGE_POINT_AVAILABILITY_LOADING]:
    changeChargePointAvailabilityLoading,
  [ChargePointTypes.CHANGE_CHARGE_POINT_AVAILABILITY_SUCCESS]:
    changeChargePointAvailabilitySuccess,
  [ChargePointTypes.CHANGE_CHARGE_POINT_AVAILABILITY_FAIL]:
    changeChargePointAvailabilityFail,
  [ChargePointTypes.UPDATE_CHARGE_POINT_FIRMWARE_LOADING]:
    updateChargePointFirmwareLoading,
  [ChargePointTypes.UPDATE_CHARGE_POINT_FIRMWARE_SUCCESS]:
    updateChargePointFirmwareSuccess,
  [ChargePointTypes.UPDATE_CHARGE_POINT_FIRMWARE_FAIL]:
    updateChargePointFirmwareFail,
  [ChargePointTypes.UPDATE_CHARGE_POINT_FIRMWARE_UPLOAD]:
    updateChargePointFirmwareUpload,
  [ChargePointTypes.FETCH_CHARGE_POINT_UPLOAD_DIAGNOSTICS_LOADING]:
    fetchChargePointUploadDiagnosticsLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_UPLOAD_DIAGNOSTICS_SUCCESS]:
    fetchChargePointUploadDiagnosticsSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_UPLOAD_DIAGNOSTICS_FAIL]:
    fetchChargePointUploadDiagnosticsFail,
  [ChargePointTypes.REQUEST_CHARGE_POINT_UPLOAD_DIAGNOSTICS_LOADING]:
    requestChargePointUploadDiagnosticsLoading,
  [ChargePointTypes.REQUEST_CHARGE_POINT_UPLOAD_DIAGNOSTICS_SUCCESS]:
    requestChargePointUploadDiagnosticsSuccess,
  [ChargePointTypes.REQUEST_CHARGE_POINT_UPLOAD_DIAGNOSTICS_FAIL]:
    requestChargePointUploadDiagnosticsFail,
  [ChargePointTypes.DIAGNOSTICS_STATUS_NOTIFICATION_LOADING]:
    diagnosticsStatusNotificationLoading,
  [ChargePointTypes.DIAGNOSTICS_STATUS_NOTIFICATION_SUCCESS]:
    diagnosticsStatusNotificationSuccess,
  [ChargePointTypes.DIAGNOSTICS_STATUS_NOTIFICATION_FAIL]:
    diagnosticsStatusNotificationFail,
  [ChargePointTypes.EMITTER_UPDATE_CHARGE_POINT_DIAGNOSTICS]:
    emitterUpdateChargePointDiagnostics,
  [ChargePointTypes.FETCH_CHARGE_POINT_PRIVACY_SCHEDULE_LOADING]:
    fetchChargePointPrivacyScheduleLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_PRIVACY_SCHEDULE_SUCCESS]:
    fetchChargePointPrivacyScheduleSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_PRIVACY_SCHEDULE_FAIL]:
    fetchChargePointPrivacyScheduleFail,
  [ChargePointTypes.DELETE_CHARGE_POINT_PRIVACY_RULES_LOADING]:
    deleteChargePointPrivacyRulesLoading,
  [ChargePointTypes.DELETE_CHARGE_POINT_PRIVACY_RULES_SUCCESS]:
    deleteChargePointPrivacyRulesSuccess,
  [ChargePointTypes.DELETE_CHARGE_POINT_PRIVACY_RULES_FAIL]:
    deleteChargePointPrivacyRulesFail,
  [ChargePointTypes.ADD_CHARGE_POINT_PRIVACY_RULES_LOADING]:
    addChargePointPrivacyRulesLoading,
  [ChargePointTypes.ADD_CHARGE_POINT_PRIVACY_RULES_SUCCESS]:
    addChargePointPrivacyRulesSuccess,
  [ChargePointTypes.ADD_CHARGE_POINT_PRIVACY_RULES_FAIL]:
    addChargePointPrivacyRulesFail,
  [ChargePointTypes.FETCH_CHARGE_POINT_ACCESS_GROUPS_LOADING]:
    fetchChargePointAccessGroupsLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_ACCESS_GROUPS_SUCCESS]:
    fetchChargePointAccessGroupsSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_ACCESS_GROUPS_FAIL]:
    fetchChargePointAccessGroupsFail,
  [ChargePointTypes.CLEAR_CHARGE_POINT_ACCESS_GROUPS]:
    clearChargePointAccessGroups,
  [ChargePointTypes.DELETE_CHARGE_POINT_ACCESS_GROUPS_BULK_LOADING]:
    deleteChargePointAccessGroupsBulkLoading,
  [ChargePointTypes.DELETE_CHARGE_POINT_ACCESS_GROUPS_BULK_SUCCESS]:
    deleteChargePointAccessGroupsBulkSuccess,
  [ChargePointTypes.DELETE_CHARGE_POINT_ACCESS_GROUPS_BULK_FAIL]:
    deleteChargePointAccessGroupsBulkFail,
  [ChargePointTypes.ADD_CHARGE_POINT_ACCESS_GROUPS_BULK_LOADING]:
    addChargePointAccessGroupsBulkLoading,
  [ChargePointTypes.ADD_CHARGE_POINT_ACCESS_GROUPS_BULK_SUCCESS]:
    addChargePointAccessGroupsBulkSuccess,
  [ChargePointTypes.ADD_CHARGE_POINT_ACCESS_GROUPS_BULK_FAIL]:
    addChargePointAccessGroupsBulkFail,
  [ChargePointTypes.FETCH_CHARGE_POINT_SUBSCRIPTIONS_LOADING]:
    fetchChargePointSubscriptionsLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_SUBSCRIPTIONS_SUCCESS]:
    fetchChargePointSubscriptionsSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_SUBSCRIPTIONS_FAIL]:
    fetchChargePointSubscriptionsFail,
  [ChargePointTypes.DELETE_CHARGE_POINT_SUBSCRIPTIONS_LOADING]:
    deleteChargePointSubscriptionsLoading,
  [ChargePointTypes.DELETE_CHARGE_POINT_SUBSCRIPTIONS_SUCCESS]:
    deleteChargePointSubscriptionsSuccess,
  [ChargePointTypes.DELETE_CHARGE_POINT_SUBSCRIPTIONS_FAIL]:
    deleteChargePointSubscriptionsFail,
  [ChargePointTypes.ADD_CHARGE_POINT_SUBSCRIPTIONS_LOADING]:
    addChargePointSubscriptionsLoading,
  [ChargePointTypes.ADD_CHARGE_POINT_SUBSCRIPTIONS_SUCCESS]:
    addChargePointSubscriptionsSuccess,
  [ChargePointTypes.ADD_CHARGE_POINT_SUBSCRIPTIONS_FAIL]:
    addChargePointSubscriptionsFail,
  [ChargePointTypes.RESET_CHARGE_POINT_SPECIFICATIONS]:
    resetChargePointSpecifications,
  [ChargePointTypes.PREPEND_CHARGE_POINT_LOG_LIST]: prependChargePointLogList,
  [ChargePointTypes.APPEND_BUFFER_CHARGE_POINT_LOG_LIST]:
    appendBufferChargePointLogList,
  [ChargePointTypes.SET_IS_CHARGE_POINT_LOGS_LIVE]: setIsChargePointLogsLive,
  [ChargePointTypes.FETCH_CHARGE_POINT_UPTIME_STATISTICS_LOADING]:
    fetchChargePointUptimeStatisticsLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_UPTIME_STATISTICS_SUCCESS]:
    fetchChargePointUptimeStatisticsSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_UPTIME_STATISTICS_FAIL]:
    fetchChargePointUptimeStatisticsFail,
  [ChargePointTypes.SET_CHARGE_POINT_STATISTICS_FILTER]:
    setChargePointStatisticsFilter,
  [ChargePointTypes.FETCH_CONNECTORS_UTILIZATION_FAIL]:
    fetchConnectorsUtilizationFail,
  [ChargePointTypes.FETCH_CONNECTORS_UTILIZATION_LOADING]:
    fetchConnectorsUtilizationLoading,
  [ChargePointTypes.FETCH_CONNECTORS_UTILIZATION_SUCCESS]:
    fetchConnectorsUtilizationSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_SUBSCRIBED_ALERTS_LOADING]:
    fetchChargePointSubscribedAlertsLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_SUBSCRIBED_ALERTS_SUCCESS]:
    fetchChargePointSubscribedAlertsSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_SUBSCRIBED_ALERTS_FAIL]:
    fetchChargePointSubscribedAlertsFail,
  [ChargePointTypes.FETCH_CHARGE_POINT_ALERTS_LOADING]:
    fetchChargePointAlertsLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_ALERTS_SUCCESS]:
    fetchChargePointAlertsSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_ALERTS_FAIL]: fetchChargePointAlertsFail,
  [ChargePointTypes.UPDATE_CHARGE_POINT_ALERTS_LOADING]:
    updateChargePointAlertsLoading,
  [ChargePointTypes.UPDATE_CHARGE_POINT_ALERTS_SUCCESS]:
    updateChargePointAlertsSuccess,
  [ChargePointTypes.UPDATE_CHARGE_POINT_ALERTS_FAIL]:
    updateChargePointAlertsFail,
  [ChargePointTypes.FETCH_CHARGE_POINT_LOCAL_LIST_LOADING]:
    fetchChargePointLocalListLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_LOCAL_LIST_SUCCESS]:
    fetchChargePointLocalListSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_LOCAL_LIST_FAIL]:
    fetchChargePointLocalListFail,
  [ChargePointTypes.UPDATE_CHARGE_POINT_LOCAL_LIST_LOADING]:
    updateChargePointLocalListLoading,
  [ChargePointTypes.UPDATE_CHARGE_POINT_LOCAL_LIST_SUCCESS]:
    updateChargePointLocalListSuccess,
  [ChargePointTypes.UPDATE_CHARGE_POINT_LOCAL_LIST_FAIL]:
    updateChargePointLocalListFail,
  [ChargePointTypes.FETCH_CHARGE_POINT_CHARGING_PROFILES_LOADING]:
    fetchChargePointChargingProfilesLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_CHARGING_PROFILES_SUCCESS]:
    fetchChargePointChargingProfilesSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_CHARGING_PROFILES_FAIL]:
    fetchChargePointChargingProfilesFail,
  [ChargePointTypes.FETCH_CHARGE_POINT_COMPOSITE_SCHEDULE_LOADING]:
    fetchChargePointCompositeScheduleLoading,
  [ChargePointTypes.FETCH_CHARGE_POINT_COMPOSITE_SCHEDULE_SUCCESS]:
    fetchChargePointCompositeScheduleSuccess,
  [ChargePointTypes.FETCH_CHARGE_POINT_COMPOSITE_SCHEDULE_FAIL]:
    fetchChargePointCompositeScheduleFail,
  [ChargePointTypes.CLEAR_CHARGE_POINT_CHARGING_PROFILE_LOADING]:
    clearChargePointChargingProfileLoading,
  [ChargePointTypes.CLEAR_CHARGE_POINT_CHARGING_PROFILE_SUCCESS]:
    clearChargePointChargingProfileSuccess,
  [ChargePointTypes.CLEAR_CHARGE_POINT_CHARGING_PROFILE_FAIL]:
    clearChargePointChargingProfileFail,
  [ChargePointTypes.SET_CHARGE_POINT_CHARGING_PROFILE_LOADING]:
    setChargePointChargingProfileLoading,
  [ChargePointTypes.SET_CHARGE_POINT_CHARGING_PROFILE_SUCCESS]:
    setChargePointChargingProfileSuccess,
  [ChargePointTypes.SET_CHARGE_POINT_CHARGING_PROFILE_FAIL]:
    setChargePointChargingProfileFail,
  [ChargePointTypes.CREATE_CHARGE_POINT_CHARGING_PROFILE_LOADING]:
    createChargePointChargingProfileLoading,
  [ChargePointTypes.CREATE_CHARGE_POINT_CHARGING_PROFILE_SUCCESS]:
    createChargePointChargingProfileSuccess,
  [ChargePointTypes.CREATE_CHARGE_POINT_CHARGING_PROFILE_FAIL]:
    createChargePointChargingProfileFail,
  [ChargePointTypes.FETCH_CONNECTOR_TYPES_LOADING]: fetchConnectorTypesLoading,
  [ChargePointTypes.FETCH_CONNECTOR_TYPES_SUCCESS]: fetchConnectorTypesSuccess,
  [ChargePointTypes.FETCH_CONNECTOR_TYPES_FAIL]: fetchConnectorTypesFail,
})
