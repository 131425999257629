import RequestState from 'Enums/RequestState'
import {
  chargePointLogFilter,
  chargePointTransactionFilter,
  chargePointStatisticsFilter,
} from 'Constants/Filters'

export const INITIAL_STATE = {
  chargePoints: [],
  chargePointTransactions: [],
  chargePointLogs: [],
  bufferedChargePointLogs: [],
  chargePointLogsFirstItemIndex: 1000000,
  chargePointSubscriptions: [],
  chargePointPrivacySchedule: [],
  chargePointConfigs: {},
  chargePointUploadDiagnostics: [],
  chargePointAccessGroups: [],
  chargePointConfigsLastUpdated: undefined,
  fetchChargePointListRequestState: RequestState.UNINITIALIZED,
  fetchChargePointRequestState: RequestState.UNINITIALIZED,
  updateChargePointRequestState: RequestState.UNINITIALIZED,
  moveChargePointRequestState: RequestState.UNINITIALIZED,
  fetchChargePointLogListRequestState: RequestState.UNINITIALIZED,
  fetchChargePointTransactionListRequestState: RequestState.UNINITIALIZED,
  fetchChargePointConfigListRequestState: RequestState.UNINITIALIZED,
  fetchChargePointSubscriptionsRequestState: RequestState.UNINITIALIZED,
  fetchChargePointPrivacyScheduleRequestState: RequestState.UNINITIALIZED,
  updateChargePointConfigRequestState: RequestState.UNINITIALIZED,
  deleteChargePointRequestState: RequestState.UNINITIALIZED,
  deleteChargePointSubscriptionsRequestState: RequestState.UNINITIALIZED,
  deleteChargePointPrivacyRulesRequestState: RequestState.UNINITIALIZED,
  addChargePointSubscriptionsRequestState: RequestState.UNINITIALIZED,
  addChargePointPrivacyRulesRequestState: RequestState.UNINITIALIZED,
  resetChargePointRequestState: RequestState.UNINITIALIZED,
  addChargePointRequestState: RequestState.UNINITIALIZED,
  deleteMovedChargePointRequestState: RequestState.UNINITIALIZED,
  clearChargePointCacheRequestState: RequestState.UNINITIALIZED,
  changeChargePointAvailabilityRequestState: RequestState.UNINITIALIZED,
  updateChargePointFirmwareRequestState: RequestState.UNINITIALIZED,
  fetchChargePointUploadDiagnosticsRequestState: RequestState.UNINITIALIZED,
  requestChargePointUploadDiagnosticsRequestState: RequestState.UNINITIALIZED,
  diagnosticsStatusNotificationRequestState: RequestState.UNINITIALIZED,
  fetchChargePointAccessGroupsRequestState: RequestState.UNINITIALIZED,
  deleteChargePointAccessGroupsBulkRequestState: RequestState.UNINITIALIZED,
  addChargePointAccessGroupsBulkRequestState: RequestState.UNINITIALIZED,
  selectedIndex: 0,
  chargePointLogPaginationOffset: 0,
  chargePointTransactionPaginationOffset: 0,
  chargePointFilterText: '',
  chargePointLogFilter: chargePointLogFilter,
  chargePointTransactionFilter: chargePointTransactionFilter,
  chargePointFirmwareUploadProgress: 0,
  isChargePointLogsLive: true,
  chargePointUptimeData: {
    uptimeGraphSeries: [
      { name: 'Online', data: [] },
      { name: 'Offline', data: [] },
      { name: 'Unstable', data: [] },
    ],
    uptimePercentages: { online: 0, offline: 0, unstable: 0 },
    isEmpty: true,
  },
  chargePointStatisticsFilter: chargePointStatisticsFilter,
  fetchChargePointUptimeStatisticsRequestState: RequestState.UNINITIALIZED,
  connectorsUtilizationData: {
    utilizationGraphSeries: [
      {
        name: 'Available',
        data: [],
      },
      {
        name: 'Charging',
        data: [],
      },
      {
        name: 'Occupied',
        data: [],
      },
      {
        name: 'Unavailable',
        data: [],
      },
      {
        name: 'Faulted',
        data: [],
      },
    ],
    utilizationPercentages: {
      available: 0,
      charging: 0,
      occupied: 0,
      unavailable: 0,
      faulted: 0,
    },
    isEmpty: true,
  },
  fetchConnectorsUtilizationRequestState: RequestState.UNINITIALIZED,
  fetchChargePointSubscribedAlertsRequestState: RequestState.UNINITIALIZED,
  subscribedAlerts: [],
  alerts: [],
  chargingProfiles: [],
  compositeSchedule: {},
  fetchChargePointAlertsRequestState: RequestState.UNINITIALIZED,
  updateChargePointAlertsRequestState: RequestState.UNINITIALIZED,
  chargePointLocalList: [],
  fetchChargePointLocalListRequestState: RequestState.UNINITIALIZED,
  updateChargePointLocalListRequestState: RequestState.UNINITIALIZED,
  fetchChargePointChargingProfilesRequestState: RequestState.UNINITIALIZED,
  fetchChargePointCompositeScheduleRequestState: RequestState.UNINITIALIZED,
  clearChargePointChargingProfileRequestState: RequestState.UNINITIALIZED,
  setChargePointChargingProfileRequestState: RequestState.UNINITIALIZED,
  createChargePointChargingProfileRequestState: RequestState.UNINITIALIZED,
  connectorTypes: {},
  fetchConnectorTypesRequestState: RequestState.UNINITIALIZED,
}
