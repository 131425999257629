import InfinityEnums from 'Enums/InfinityEnums'

const getFormattedChargePointConnectors = (state) => {
  const chargePoints = getChargePoints(state)
  let status = ''
  let connectors = [...Array(chargePoints.length)].map((e) => Array())
  chargePoints.map((chargePoint, index) => {
    chargePoint.connectors.map((connector) => {
      if (chargePoint.isOnline) status = connector.status
      else status = InfinityEnums.ConnectorStatus.OFFLINE
      if (connector.uid !== 0) fillConnectors(status, connectors, index)
    })
    connectors[index].sort((a, b) => (a.status > b.status ? 1 : -1))
  })
  return connectors
}

const getFastestConnector = (state) => {
  const chargePoint = getSelectedChargePoint(state)
  let maxPower = 0
  if (chargePoint)
    chargePoint.connectors.map((connector) => {
      if (connector.maxPower > maxPower && connector.uid !== 0)
        maxPower = connector.maxPower
    })
  return maxPower
}

const fillConnectors = (status, connectors, index) => {
  let newStatus = status
  if (
    status === InfinityEnums.ConnectorStatus.PREPARING ||
    status === InfinityEnums.ConnectorStatus.SUSPENDEDEV ||
    status === InfinityEnums.ConnectorStatus.SUSPENDEDEVSE ||
    status === InfinityEnums.ConnectorStatus.RESERVED
  )
    newStatus = InfinityEnums.ConnectorStatus.PREPARING
  else if (
    status === InfinityEnums.ConnectorStatus.CHARGING ||
    status === InfinityEnums.ConnectorStatus.FINISHING
  )
    newStatus = InfinityEnums.ConnectorStatus.CHARGING
  else if (
    status === InfinityEnums.ConnectorStatus.UNAVAILABLE ||
    status === InfinityEnums.ConnectorStatus.FAULTED
  )
    newStatus = InfinityEnums.ConnectorStatus.UNAVAILABLE
  const value = connectors[index].findIndex(
    (connector) => connector.status === newStatus,
  )
  if (value !== -1) connectors[index][value].number += 1
  else connectors[index].push({ number: 1, status: newStatus })
}

const getChargePoints = (state) => {
  return state.main.chargePointState.chargePoints
}

const getChargePointConnectors = (state) => {
  const chargePoints = getChargePoints(state)
  const selectedIndex = getSelectedIndex(state)
  let response = []
  if (chargePoints[selectedIndex]) {
    const chargePoint = chargePoints[selectedIndex]
    chargePoint.connectors.map((connector) => {
      response.push({
        _id: connector._id,
        maxPower: connector.maxPower,
        uid: connector.uid,
        type: connector.type,
        status: connector.status,
        statusUpdatedAt: connector.statusUpdatedAt,
        session: connector.session,
        reservation: connector.reservation,
        meterValue: connector.meterValue,
        errorCode: connector.errorCode,
        vendorErrorCode: connector.vendorErrorCode,
        vendorId: connector.vendorId,
      })
    })
  }

  return response
}

const getChargePointUid = (state) => {
  const selectedIndex = getSelectedIndex(state)
  const chargePoints = getChargePoints(state)
  if (chargePoints[selectedIndex]) return chargePoints[selectedIndex].uid
  else return ''
}

const getSelectedChargePoint = (state) => {
  return state.main.chargePointState.chargePoints[
    state.main.chargePointState.selectedIndex
  ]
}

const getChargePointLogs = (state) => {
  let result = []
  for (let chargePointLog of state.main.chargePointState.chargePointLogs) {
    if (chargePointLog.responseMessage) {
      result.push({ ...chargePointLog, requestMessage: null })
    }
    if (chargePointLog.requestMessage) {
      result.push({ ...chargePointLog, responseMessage: null })
    }
  }
  return result
}
const getChargePointLogsFirstItemIndex = (state) => {
  return state.main.chargePointState.chargePointLogsFirstItemIndex
}

const getBufferedChargePointLogs = (state) => {
  return state.main.chargePointState.bufferedChargePointLogs
}

const getChargePointTransactions = (state) => {
  return state.main.chargePointState.chargePointTransactions
}

const getChargePointConfigs = (state) => {
  return state.main.chargePointState.chargePointConfigs
}

const getChargePointSubscriptions = (state) => {
  return state.main.chargePointState.chargePointSubscriptions
}

const getFetchChargePointSubscriptionsRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointSubscriptionsRequestState
}

const getChargePointConfigsLastUpdated = (state) => {
  return state.main.chargePointState.chargePointConfigsLastUpdated
}

const getFetchChargePointListRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointListRequestState
}

const getFetchChargePointRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointRequestState
}

const getUpdateChargePointRequestState = (state) => {
  return state.main.chargePointState.updateChargePointRequestState
}

const getFetchChargePointLogListRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointLogListRequestState
}

const getFetchChargePointTransactionListRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointTransactionListRequestState
}

const getFetchChargePointConfigListRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointConfigListRequestState
}

const getUpdateChargePointConfigRequestState = (state) => {
  return state.main.chargePointState.updateChargePointConfigRequestState
}

const getDeleteChargePointRequestState = (state) => {
  return state.main.chargePointState.deleteChargePointRequestState
}

const getAddChargePointRequestState = (state) => {
  return state.main.chargePointState.addChargePointRequestState
}

const getSelectedIndex = (state) => {
  return state.main.chargePointState.selectedIndex
}

const getChargePointLogPaginationOffset = (state) => {
  return state.main.chargePointState.chargePointLogPaginationOffset
}

const getChargePointTransactionPaginationOffset = (state) => {
  return state.main.chargePointState.chargePointTransactionPaginationOffset
}

const getChargePointFilterText = (state) => {
  return state.main.chargePointState.chargePointFilterText
}

const getChargePointLogFilter = (state) => {
  return state.main.chargePointState.chargePointLogFilter
}

const getChargePointTransactionFilter = (state) => {
  return state.main.chargePointState.chargePointTransactionFilter
}

const getMoveChargePointRequestState = (state) => {
  return state.main.chargePointState.moveChargePointRequestState
}

const getResetChargePointRequestState = (state) => {
  return state.main.chargePointState.resetChargePointRequestState
}

const getDeleteMovedChargePointRequestState = (state) => {
  return state.main.chargePointState.deleteMovedChargePointRequestState
}

const getChargePointName = (state) => {
  return state.main.chargePointState.chargePoints[
    state.main.chargePointState.selectedIndex
  ]?.name
}

const getClearChargePointCacheRequestState = (state) => {
  return state.main.chargePointState.clearChargePointCacheRequestState
}

const getChangeChargePointAvailabilityRequestState = (state) => {
  return state.main.chargePointState.changeChargePointAvailabilityRequestState
}

const getUpdateChargePointFirmwareRequestState = (state) => {
  return state.main.chargePointState.updateChargePointFirmwareRequestState
}

const getChargePointFirmwareUploadProgress = (state) => {
  return state.main.chargePointState.chargePointFirmwareUploadProgress
}

const getChargePointUploadDiagnostics = (state) => {
  return state.main.chargePointState.chargePointUploadDiagnostics
}

const getFetchChargePointUploadDiagnosticsRequestState = (state) => {
  return state.main.chargePointState
    .fetchChargePointUploadDiagnosticsRequestState
}

const getRequestChargePointUploadDiagnosticsRequestState = (state) => {
  return state.main.chargePointState
    .requestChargePointUploadDiagnosticsRequestState
}

const getDiagnosticsStatusNotificationRequestState = (state) => {
  return state.main.chargePointState.diagnosticsStatusNotificationRequestState
}
const getDeleteChargePointSubscriptionsRequestState = (state) => {
  return state.main.chargePointState.deleteChargePointSubscriptionsRequestState
}

const getAddChargePointSubscriptionsRequestState = (state) => {
  return state.main.chargePointState.addChargePointSubscriptionsRequestState
}
const getChargePointPrivacySchedule = (state) => {
  return state.main.chargePointState.chargePointPrivacySchedule
}

const getFetchChargePointPrivacyScheduleRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointPrivacyScheduleRequestState
}
const getDeleteChargePointPrivacyRulesRequestState = (state) => {
  return state.main.chargePointState.deleteChargePointPrivacyRulesRequestState
}
const getAddChargePointPrivacyRulesRequestState = (state) => {
  return state.main.chargePointState.addChargePointPrivacyRulesRequestState
}
const getChargePointAccessGroups = (state) => {
  return state.main.chargePointState.chargePointAccessGroups
}

const getAddChargePointAccessGroupsBulkRequestState = (state) => {
  return state.main.chargePointState.addChargePointAccessGroupsBulkRequestState
}

const getDeleteChargePointAccessGroupsBulkRequestState = (state) => {
  return state.main.chargePointState
    .deleteChargePointAccessGroupsBulkRequestState
}

const getFetchChargePointAccessGroupsRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointAccessGroupsRequestState
}
const getIsChargePointLogsLive = (state) => {
  return state.main.chargePointState.isChargePointLogsLive
}

const getChargePointPrivacy = (state) => {
  return state.main.chargePointState.chargePoints[
    state.main.chargePointState.selectedIndex
  ]?.isPrivate
}

const getChargePointAppVisibility = (state) => {
  return state.main.chargePointState.chargePoints[
    state.main.chargePointState.selectedIndex
  ]?.isHidden
}

const getChargePointUptimeStatistics = (state) => {
  return state.main.chargePointState.chargePointUptimeData
}

const getFetchChargePointUptimeStatisticsRequestState = (state) => {
  return state.main.chargePointState
    .fetchChargePointUptimeStatisticsRequestState
}

const getChargePointStatisticsFilter = (state) => {
  return state.main.chargePointState.chargePointStatisticsFilter
}

const getConnectorsUtilization = (state) => {
  return state.main.chargePointState.connectorsUtilizationData
}

const getFetchConnectorsUtilizationRequestState = (state) => {
  return state.main.chargePointState.fetchConnectorsUtilizationRequestState
}

const getChargePointSubscribedAlerts = (state) => {
  return state.main.chargePointState.subscribedAlerts
}

const getFetchChargePointSubscribedAlertsRequestState = (state) => {
  return state.main.chargePointState
    .fetchChargePointSubscribedAlertsRequestState
}

const getChargePointAlerts = (state) => {
  return state.main.chargePointState.alerts
}

const getFetchChargePointAlertsRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointAlertsRequestState
}

const getUpdateChargePointAlertsRequestState = (state) => {
  return state.main.chargePointState.updateChargePointAlertsRequestState
}

const getChargePointLocalList = (state) => {
  return state.main.chargePointState.chargePointLocalList
}

const getFetchChargePointLocalListRequestState = (state) => {
  return state.main.chargePointState.fetchChargePointLocalListRequestState
}

const getUpdateChargePointLocalListRequestState = (state) => {
  return state.main.chargePointState.updateChargePointLocalListRequestState
}
const getChargingProfiles = (state) => {
  return state.main.chargePointState.chargingProfiles
}

const getFetchChargePointChargingProfilesRequestState = (state) => {
  return state.main.chargePointState
    .fetchChargePointChargingProfilesRequestState
}

const getCompositeSchedule = (state) => {
  return state.main.chargePointState.compositeSchedule
}

const getFetchChargePointCompositeScheduleRequestState = (state) => {
  return state.main.chargePointState
    .fetchChargePointCompositeScheduleRequestState
}

const getClearChargePointChargingProfileRequestState = (state) => {
  return state.main.chargePointState.clearChargePointChargingProfileRequestState
}

const getSetChargePointChargingProfileRequestState = (state) => {
  return state.main.chargePointState.setChargePointChargingProfileRequestState
}

const getCreateChargePointChargingProfileRequestState = (state) => {
  return state.main.chargePointState
    .createChargePointChargingProfileRequestState
}

const getConnectorTypes = (state) => {
  return state.main.chargePointState.connectorTypes
}

const getFetchConnectorTypesRequestState = (state) => {
  return state.main.chargePointState.fetchConnectorTypesRequestState
}

const ChargePointSelectors = {
  getFormattedChargePointConnectors,
  getFastestConnector,
  getChargePoints,
  getChargePointConnectors,
  getChargePointUid,
  getSelectedChargePoint,
  getChargePointLogs,
  getChargePointLogsFirstItemIndex,
  getBufferedChargePointLogs,
  getChargePointTransactions,
  getChargePointConfigs,
  getFetchChargePointListRequestState,
  getFetchChargePointRequestState,
  getUpdateChargePointRequestState,
  getFetchChargePointLogListRequestState,
  getFetchChargePointTransactionListRequestState,
  getFetchChargePointConfigListRequestState,
  getUpdateChargePointConfigRequestState,
  getDeleteChargePointRequestState,
  getAddChargePointRequestState,
  getSelectedIndex,
  getChargePointLogPaginationOffset,
  getChargePointTransactionPaginationOffset,
  getChargePointFilterText,
  getChargePointLogFilter,
  getChargePointTransactionFilter,
  getMoveChargePointRequestState,
  getResetChargePointRequestState,
  getDeleteMovedChargePointRequestState,
  getChargePointName,
  getClearChargePointCacheRequestState,
  getChargePointConfigsLastUpdated,
  getChangeChargePointAvailabilityRequestState,
  getUpdateChargePointFirmwareRequestState,
  getChargePointFirmwareUploadProgress,
  getChargePointUploadDiagnostics,
  getFetchChargePointUploadDiagnosticsRequestState,
  getRequestChargePointUploadDiagnosticsRequestState,
  getDiagnosticsStatusNotificationRequestState,
  getChargePointSubscriptions,
  getFetchChargePointSubscriptionsRequestState,
  getDeleteChargePointSubscriptionsRequestState,
  getAddChargePointSubscriptionsRequestState,
  getChargePointPrivacySchedule,
  getFetchChargePointPrivacyScheduleRequestState,
  getDeleteChargePointPrivacyRulesRequestState,
  getAddChargePointPrivacyRulesRequestState,
  getChargePointAccessGroups,
  getAddChargePointAccessGroupsBulkRequestState,
  getDeleteChargePointAccessGroupsBulkRequestState,
  getFetchChargePointAccessGroupsRequestState,
  getIsChargePointLogsLive,
  getChargePointPrivacy,
  getChargePointAppVisibility,
  getChargePointUptimeStatistics,
  getFetchChargePointUptimeStatisticsRequestState,
  getChargePointStatisticsFilter,
  getConnectorsUtilization,
  getFetchConnectorsUtilizationRequestState,
  getChargePointSubscribedAlerts,
  getFetchChargePointSubscribedAlertsRequestState,
  getChargePointAlerts,
  getFetchChargePointAlertsRequestState,
  getUpdateChargePointAlertsRequestState,
  getChargePointLocalList,
  getFetchChargePointLocalListRequestState,
  getUpdateChargePointLocalListRequestState,
  getChargingProfiles,
  getFetchChargePointChargingProfilesRequestState,
  getCompositeSchedule,
  getFetchChargePointCompositeScheduleRequestState,
  getClearChargePointChargingProfileRequestState,
  getSetChargePointChargingProfileRequestState,
  getCreateChargePointChargingProfileRequestState,
  getConnectorTypes,
  getFetchConnectorTypesRequestState,
}

export default ChargePointSelectors
